.drawContainer {
  width: 60%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background: url('../../assets/image/mainPage/result.png') no-repeat;
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
}

.activeRafflesContainer {
  width: 118.2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 1360px) {
    width: 80%;
  }
}

.titleOfBlockActiveRaffles {
  font-family:
    Nunito Sans,
    sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 39.2px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 360px) {
    font-size: 24px;
    line-height: 27px;
  }
}

.blockActiveRaffles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
  @media screen and (max-width: 360px) {
    margin: 30px 0;
  }
}

@media screen and (max-width: 1150px) {
  .drawContainer {
    width: 100%;
  }
}
