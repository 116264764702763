@import 'variables';
@import 'mixins';

// MANAGER STATS
.statsBlock {
  @include flex(row, center, center);
  height: 161px;
  flex-grow: 1;
  @include blockStyle;
  @media (max-width: 750px) {
    width: 100%;
    height: 128px;
  }

  .statsWrapper {
    width: 80%;
    @include flex(column, center, center);
    gap: 10px;

    .statsHeader {
      width: 100%;
      @include text($gilroyFont, 700, 20px, 25px);
      @include flex(row, space-between, center);

      span {
        @include adapt-font(20, 14, 25, 20);
      }
    }

    .statsInfo {
      width: 100%;
      @include text($gilroyFont, 400, 16px, 19px);
      @include flex(row, space-between, center);

      span {
        @include adapt-font(16, 14, 19, 19);
      }
    }
    .cashItem {
      color: $cashColor;
    }
  }
}

//HOME PAGE TABLE WRAPPER
.clientsBlock {
  @include blockStyle();
  @include flex(column, flex-start, center);
  height: 340px;
  margin-top: 40px;
  padding: 20px 0;
  @media (max-width: 750px) {
    margin-top: 20px;
  }
}

%tableCell {
  width: inherit;
  @include flex(row, flex-start, center);
  @include cellStyle();

  span {
    padding-left: 10px;
    @include text($mulishFont, 500, 14px, 20px);
    @include overflow();
  }
}
