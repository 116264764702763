@import '../../styles/variables';
@import '../../styles/mixins';

.blockWrapper {
  height: 100%;
  @include flex(column, center, center);
  gap: 10px;

  .blockHeader {
    @include flex(row, center, center);
    gap: 5px;

    .blockTitle {
      @include text($gilroyFont, 700, 20px, 25px);
      @include adapt-font(20, 14, 25, 17);
    }

    .titleWithTipWrapper {
      @include flex(row, center, center);
      gap: 5px;
      cursor: pointer;

      .blockTitleWithTip {
        @extend .blockTitle;
      }

      .blockTitleIcon {
        width: 16px;
      }
    }
  }

  .blockInfo {
    @include flex(row, center, center);
    @include text($mulishFont, 500, 16px, 20px);
    @include cellStyle();
    width: 100%;
    padding: 0 7px;
    pointer-events: none;
  }

  .cursorPointer {
    cursor: pointer;
    pointer-events: unset;
  }
}
