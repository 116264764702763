@import '../../sales/common/styles/variables';
@import '../../sales/common/styles/mixins';

.tableHeader {
  @include flex(row, center, center);
  position: relative;
  height: 42px;
  @media (max-width: 560px) {
    @include flex(column, center, center);
    height: max-content;
  }
  .tableTitleWrapper {
    @media (max-width: 560px) {
      padding: 15px 0;
      order: 2;
    }
  }
  .searchWrapper {
    position: absolute;
    width: 230px;
    right: 10px;
    top: 2px;
    @media (max-width: 650px) {
      position: unset;
      width: 100%;
      padding: 15px;
      @include flex(column, center, center);
    }
  }
}
