@import '../../../../../../common/styles/variables';
@import '../../../../../../common/styles/mixins';

.botLink {
  @include text($gilroyFont, 500, 20px, 25px, #3751ff);
  text-decoration: underline;
  cursor: pointer;
}
.bold {
  font-weight: 700;
}