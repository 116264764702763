@import '../../../../../common/styles/variables';
@import '../../../../../common/styles/mixins';
@import '../../../../../common/styles/CommonStyles.module';

.managerInfo {
  @include flex(column, flex-start, center);

  .telegramBlock {
    display: flex;

    .telegram {
      .telegramHeader {
        @include text($mulishFont, 700, 12px, 14px);
        color: #9fa2b4;
        text-align: left;
      }

      .telegramInfo {
        width: 220px;
        padding: 12px 15px;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid #f0f1f7;
        background: #fcfdfe;
      }
    }

    .managerInfoButton {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-end;

      @media (max-width: 499px) {
        display: none;
      }

      button {
        width: 316px;
        height: 48px;
        margin: 0 0 0 10px;
        padding: 0;
        border: none;
        border-radius: 8px;
        background-color: #27ae60;
        box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);
        cursor: pointer;
        @include text($mulishFont, 700, 16px, 14px);
        color: #ffffff;
        transition: 0.3s;

        &:hover {
          background-color: #1e8a4c;
        }
      }
    }
  }

  .dataBlock {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;

    .balanceBlock {
      width: 240px;
      padding: 6px;

      .balanceTitle {
        @include text($mulishFont, 700, 12px, 14px);
        color: #9fa2b4;
        text-align: left;
      }

      .balanceInfo {
        width: 190px;
        padding: 10px;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid #f0f1f7;
        background: #fcfdfe;
      }
    }

    .managerData {
      display: flex;

      .teamBlock,
      .freeBlock,
      .paidBlock {
        padding: 6px;

        .teamTitle,
        .freeTitle,
        .paidTitle {
          @include text($mulishFont, 700, 12px, 14px);
          color: #9fa2b4;
          text-align: left;
        }

        .teamInfo,
        .freeInfo,
        .paidInfo {
          width: 80px;
          padding: 10px;
          margin-top: 10px;
          border-radius: 8px;
          border: 1px solid #f0f1f7;
          background: #fcfdfe;
        }
      }
    }

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    flex-direction: row;
    margin-top: 30px;

    & > :first-of-type {
      height: 48px;
      width: 320px;
      background-color: #56ccf2;
    }

    & > :first-of-type:hover {
      background-color: #4ebce0;
    }

    :nth-child(2) {
      height: 48px;
      width: 250px;
      background-color: #eb5858;
    }

    :nth-child(2):hover {
      background-color: #d75151;
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 8px;
      box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);
      cursor: pointer;
      @include text($mulishFont, 700, 16px, 14px);
      color: #ffffff;
      transition: 0.3s;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }

      &:hover {
        background-color: #1e8a4c;
      }
    }

    @media (max-width: 499px) {
      flex-direction: column;
      gap: 20px;

      & > :first-of-type {
        width: 296px;
      }

      :nth-child(2) {
        width: 296px;
      }
    }
  }
}
