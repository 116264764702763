/* reset */
button {
  all: unset;
}

.root {
  width: 52px;
  height: 32px;
  background-color: var(--color-black-2);
  border-radius: 32px;
  position: relative;
}

.root[data-state='checked'] {
  background-color: var(--color-violet-3);
}

.thumb {
  display: block;
  width: 28px;
  height: 28px;
  background-color: var(--color-background-2);
  border-radius: 50%;
  box-shadow: 00px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}
.thumb[data-state='checked'] {
  transform: translateX(22px);
}
