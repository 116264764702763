@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';
@import '../../../../common/styles/CommonStyles.module';

.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;

  .table {
    margin-top: 20px;
    border-collapse: collapse;

    tbody tr {
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background-color: #f6f5f5;
      }
    }

    .dateCol,
    .freeCol,
    .sumCol,
    .moreCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;

      @media (max-width: 599px) {
        display: none;
      }
    }

    .idCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
      @include flex(row, center, center);
      gap: 5px;
      @media (max-width: 599px) {
        display: none;
      }
      .sort_icon {
        width: 25px;
        cursor: pointer;
      }
    }

    .emailCol,
    .theamCol,
    .paidCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .date {
      padding: 11px 10px;
      width: 135px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 75px;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }

    .id {
      padding: 11px 10px;
      width: 70px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 45px;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }

    .email {
      padding: 11px 10px;
      width: 180px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 45px;
      }

      @media (max-width: 599px) {
        width: 120px;
      }
    }

    .team {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 65px;
      }

      @media (max-width: 599px) {
        width: 65px;
      }
    }

    .free {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 65px;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }

    .paid {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 65px;
      }

      @media (max-width: 599px) {
        width: 65px;
      }
    }

    .sum {
      width: 80px;
      padding: 11px 10px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }

    .more {
      width: 170px;
      padding: $cellMobilePadding;

      @media (max-width: 799px) {
        display: none;
      }
    }
  }
}
