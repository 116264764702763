.infoContainer {
  width: 850px;
  text-align: start;
  background: #ffecce;
  padding: 20px;
  box-sizing: border-box;
  margin: 50px auto;
  line-height: normal;
  font-size: 16px;
}
.title {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}
.text {
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1150px) {
  .infoContainer {
    width: 90%;
  }
}
