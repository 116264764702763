@import '../../sales/common/styles/variables';
@import '../../sales/common/styles/mixins';

.adminDrawsNavWrapper {
  min-height: 42px;
  @include flex(row, center, center);
  position: relative;
  @media (max-width: 940px) {
    @include flex(row, space-around, center);
    flex-wrap: wrap-reverse;
  }

  .drawsSearch {
    position: absolute;
    right: 10px;
    top: 2px;
    min-width: 256px;
    @media (max-width: 940px) {
      position: unset;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.adminDrawsCardsWrapper {
  padding: 32px 0;
  @include flex(row, center, center);
  flex-wrap: wrap;
  gap: 30px;
}
