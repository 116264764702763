.pageWrapper {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 165px);
  padding: 40px 0;
  .mainContainer {
    width: 80%;
    @media (max-width: 1250px) {
      width: 95%;
    }
  }
}
.authBackground {
  background-color: #fafafa;
}
