.winnersContainer {
  width: 85rem;
  margin: 0 auto;
}

.winnersTitle {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 4rem 0;
  text-transform: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  border-radius: 2.5rem;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgb(0 0 0 / 10%);
  backdrop-filter: blur(6px);
  justify-content: space-around;
}

.imageBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  width: 240px;
  text-align: center;
}

.img {
  width: 240px;
  height: 230px;
  border-radius: 25px;
  object-fit: cover;
  margin-top: 10px;
}

.imageBlock > p {
  width: 180px;
  margin: 0;
  align-self: center;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: normal;
}

.image {
  width: 100%;
}

.image img {
  border-radius: 2.5rem;
  object-fit: cover;
  width: 100%;
  height: 24rem;
}

.wrapper {
  width: 42rem;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.winners {
  max-width: 37.5rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 1.5rem;
}

.h4 {
  font-size: 2.4rem;
  padding: 0;
  line-height: normal;
  font-weight: 700;
}

.noWinnersText {
  width: 100%;
  color: #5b5757;
}

@media screen and (max-width: 1150px) {
  .container {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    align-items: center;
  }

  .winnersContainer {
    width: 100%;
  }

  .winners {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin-left: 0;
    width: 100%;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .h4 {
    margin-bottom: 2rem;
  }

  .winners {
    word-break: break-word;
    flex-direction: column;
  }
}
