@import '../../../../styles/variable';

.main {
  position: fixed;
  bottom: 1rem;
  right: 0;
  padding: 1rem 8rem;
  z-index: 9999;
}

.common {
  width: 52.4rem;
  height: 10.4rem;
  background: var(--color-background-2);
  border-radius: 2.4rem;
  box-shadow: 0 4px 4px 0 rgba(8, 8, 8, 0.15);
  padding: 2.4rem;
  display: flex;
  justify-content: space-between;
}

.blockText {
  width: 24.1rem;
  text-align: left;
}

.linkCookei {
  cursor: pointer;
}

.buttonUiKit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText {
  font-family: var(--font-family-text);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  text-transform: uppercase;
}

.blockButtonAndIcon {
  display: flex;
  width: 22.7rem;
  justify-content: space-between;
}

@media screen and (max-width: $screen-size-average) {
  .main {
    padding: 1rem 1rem;
  }
  .common {
    width: 52.4rem;
    height: 9.2rem;
  }

  .iconClose {
    transform: scale(0.8);
    align-self: center;
  }

  .buttonText {
    font-size: var(--font-size-m);
  }

  .blockButtonAndIcon {
    width: 21.1rem;
  }

  .blockText {
    width: 27rem;
  }
}

@media screen and (max-width: $screen-size-intermediate) {
  .main {
    padding: 0.5rem 0.8rem;
    right: 40%;
    transform: translateX(40%);
  }
  .common {
    width: 32.8rem;
    height: 12.6rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .blockButtonAndIcon {
    width: 29.6rem;
    height: 4rem;
  }

  .blockText {
    width: 29rem;
  }

  .buttonUiKit {
    width: 24.8rem;
  }

  .iconClose {
    transform: scale(0.7);
  }
}
