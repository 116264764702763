@import '../../styles/mixins';
/* reset */
button,
h3 {
  all: unset;
}

.item {
  overflow: hidden;
  margin-top: 8px;
  border-radius: 40px;
  background-color: var(--color-black-7);
  @media (max-width: 993px) {
    border-radius: 24px;
  }
  @media (max-width: 557px) {
    border-radius: 32px;
  }
  &:first-child {
    margin-top: 0;
  }
}

.header {
  position: relative;
  cursor: pointer;
  border-radius: 40px;
  background-color: var(--color-black-7);
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 16px;

  .title {
    text-align: start;
    flex: 1 1 auto;
  }

  .icon {
    @include transition(transform);
    flex-shrink: 0;
  }

  &[data-state='open'] > .icon {
    transform: rotate(45deg);
    @include transition(transform);
  }
  @include transition(background-color);
  @media (max-width: 993px) {
    padding: 16px 24px;
    border-radius: 24px;
  }
  @media (max-width: 557px) {
    min-height: 40px;
    padding: 8px 16px;
    border-radius: 32px;
  }
  p {
    @include transition(color);
    transition: color 0.2s ease-in-out;

    .icon svg {
      @include transition(color);
    }
  }

  &:hover:not(&[data-state='open']) {
    background-color: var(--color-violet-1);
    @include transition(background-color);

    p {
      color: var(--color-text-black);
      @include transition(color);
    }

    .icon svg {
      color: var(--color-text-black);
      @include transition(color);
    }
  }

  .trigger {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.content {
  background-color: var(--color-black-7);

  .contentContainer {
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    @media (max-width: 993px) {
      padding-bottom: 48px;
      gap: 16px;
    }
    @media (max-width: 577px) {
      padding-bottom: 40px;
    }
    .contentBtn {
      padding: 0 72px;
      @media (max-width: 993px) {
        padding: 0 48px;
      }
      @media (max-width: 557px) {
        padding: 0 40px;
      }
    }

    .contentText {
      text-align: start;
      padding: 16px 72px;
      @media (max-width: 993px) {
        padding: 0 48px;
      }
      @media (max-width: 577px) {
        padding: 0 16px 0 40px;
      }
      p {
        white-space: pre-wrap;
        font-size: 16px;
        letter-spacing: 0.28px;
        @media (max-width: 769px) {
          @include adapt-font-M-S(16, 12, 0.28, 0.2);
        }
      }
    }
  }

  &[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    animation-fill-mode: forwards;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    // display: none;
    height: 0;
  }
}
