@import '../../../../../common/styles/variables';
@import '../../../../../common/styles/mixins';
@import '../../../../../common/styles/CommonStyles.module';

.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;

  .table {
    margin-top: 20px;
    border-collapse: collapse;

    tbody tr {
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background-color: #f6f5f5;
      }
    }

    .dateCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .idCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;

      @media (max-width: 570px) {
        display: none;
      }
    }

    .sumCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .subscriptionCol {
      @extend .sumCol;
    }

    .telegramCountCol {
      @extend .sumCol;
    }

    .editCol {
      @extend .sumCol;
      @media (max-width: 775px) {
        display: none;
      }
    }

    .date {
      padding: 11px 10px;
      width: 165px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 165px;
      }

      @media (max-width: 599px) {
        width: 70px;
        padding: $cellMobilePadding;
      }
    }

    .sum {
      width: 80px;
      padding: 11px 10px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 80px;
      }

      @media (max-width: 599px) {
        width: 65px;
        padding: $cellMobilePadding;
      }
    }

    .id {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 570px) {
        display: none;
      }
    }

    .telegram {
      padding: 11px 10px;
      width: 180px;

      a {
        color: #000000;
      }

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 120px;
      }

      @media (max-width: 599px) {
        width: 120px;
        padding: $cellMobilePadding;
      }
    }

    .edit {
      width: 170px;
      padding: $cellMobilePadding;
      @media (max-width: 775px) {
        display: none;
      }
    }
  }
}
