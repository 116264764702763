@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';

.pageHeader {
  @include flex(row, center, center);
  position: relative;
  height: 42px;
  @media (max-width: 560px) {
    @include flex(column, center, center);
    height: max-content;
  }
  .tableTitleWrapper {
    @media (max-width: 560px) {
      padding: 15px 0;
      order: 2;
    }
  }
}
