@import '../../styles/mixins';

@mixin button {
  all: unset;

  cursor: pointer;

  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 15px 48px;

  position: relative;
  overflow: hidden;
  @media (max-width: 1440px) {
    @include adapt-paddingTB(15, 10.5, 1440, 768);
    @include adapt-paddingLR(48, 32, 1440, 768);
  }
  @media (max-width: 769px) {
    padding: 10.5px 32px;
    //@include adapt-paddingTB(10.5, 7.5, 768, 360);
    //@include adapt-paddingLR(32, 24, 768, 360);
  }
  border-radius: 40px;
  .text {
    text-transform: uppercase;
    color: var(--color-text-black);
    transition: color 0.5s;
  }

  &:disabled {
    cursor: initial;
  }

  &.small {
    padding: 7.5px 24px;
  }

  &.medium {
    padding: 10.5px 32px;
    @media (max-width: 769px) {
      @include adapt-paddingTB(10.5, 7.5, 768, 360);
      @include adapt-paddingLR(32, 24, 768, 360);
    }
    &.nonAdapt {
      padding: 10.5px 32px;
    }
  }
  & div {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    //button animation not correct work in the end, when we when
    // suddenly remove the mouse from the button, the animation collapses
    transition:
      color 0.5s ease-in-out 0.05s,
      height 0.5s cubic-bezier(1, 1.2, 1, 1.2) 0.1s,
      width 0.5s 0.1s,
      transform 0.6s cubic-bezier(1, 1.2, 1, 1.2) 0.05s;

    //old versia of animation button
    //transition:
    //  width 0.5s,
    //  height 0.5s cubic-bezier(0.8, 1, 0.8, 1) 0s;
  }
  &:hover::before {
    width: 800px;
    height: 800px;
  }
}

.primary {
  @include button;
  background-color: var(--color-green-3);

  &.dark {
    &::before {
      background-color: var(--color-background-1);
    }
    &:hover {
      outline: none;

      .text {
        color: var(--color-text-white);
      }
    }

    &:active {
      .text {
        color: var(--color-black-3);
      }
    }
  }

  &:hover {
    outline: none;
  }

  &:active {
    background-color: var(--color-black-1);
  }
  &::before {
    background-color: var(--color-background-2);
  }
}

.secondary {
  @include button;
  background-color: var(--color-background-1);
  outline: 1px solid var(--color-background-2);

  .text {
    color: var(--color-text-white);
  }

  &:active,
  &:hover {
    outline: 1px solid var(--color-green-3);
    .text {
      color: var(--color-text-black);
    }
  }

  &::before {
    background-color: var(--color-green-3);
  }

  &:active {
    outline-color: var(--color-black-3);

    .text {
      color: var(--color-black-3);
    }
  }

  &.dark {
    background-color: var(--color-background-2);
    outline-color: var(--color-background-1);

    .text {
      color: var(--color-text-black);
    }

    &:hover,
    &:active {
      outline-color: var(--color-green-3);
    }

    &:active {
      outline-color: var(--color-text-black-5);

      .text {
        color: var(--color-text-black-5);
      }
    }
  }
}

.link {
  all: unset;
  position: relative;
  cursor: pointer;

  display: inline-flex;

  box-sizing: border-box;
  padding: 2px 0;

  .text {
    text-transform: uppercase;
  }

  &:disabled {
    cursor: initial;
  }

  .text {
    color: var(--color-text-white);
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: var(--color-background-2);
    bottom: 2px;
    scale: 0;
    transition: scale 0.2s ease-in-out;
  }

  &:active,
  &:hover {
    &:after {
      scale: 1;
      transition: scale 0.2s ease-in-out;
    }
  }

  &.dark {
    &:after {
      background-color: var(--color-background-1);
    }
    .text {
      color: var(--color-text-black);
    }

    &:hover,
    &:active {
    }
  }
}

.noAnimation {
  &:before {
    content: '';
    display: none;
  }
  &:active,
  &:hover {
    .text {
      color: var(--color-background-2);
    }
  }
}
.fullWidth {
  @include button;
  width: 100%;
}
