@import '../../../sales/common/styles/mixins';
@import '../../../sales/common/styles/variables';

.modalDrawCard {
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  @include flex(column, center, center);
  border-radius: $drawCardBorderRadius;

  .modalDrawCardImage {
    width: 290px;
    border-radius: $drawCardBorderRadius;
  }

  .modalDrawCardHeader {
    @include text($gilroyFont, 700, 16px, 16px);
    margin-top: 20px;
  }

  .modalDrawCardInfo {
    @include flex(column, center, center);
    padding: 15px;
    gap: 20px;
  }

  .modalDrawCardDescription {
    width: 100%;
    @include text($gilroyFont, 500, 16px, 16px);
  }

  .modalDrawCardEndDate {
    width: 100%;
    text-align: left;
    @include text($gilroyFont, 500, 16px, 16px);
  }

  .modalDrawCardText {
    width: 100%;
    @include text($gilroyFont, 500, 16px, 16px);
  }
}
