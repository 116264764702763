@import '../../../common/styles/mixins';
@import '../../../common/styles/variables';

.statsBlockWrapper {
  @include flex(row, space-between, center);
  width: 100%;
  min-height: 161px;
  margin-top: 40px;
  gap: 20px;
  @media (max-width: 750px) {
    @include flex(column, flex-start, center);
    margin-top: 20px;
  }
}
