@font-face {
  font-family: 'NeutralFaceBold';
  src: local('NeutralFaceBold'), url('../assets/fonts/NeutralFaceBold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'NeutralFaceRegular';
  src: local('NeutralFaceRegular'), url('../assets/fonts/NeutralFaceRegular.ttf');
  font-weight: 400;
}

html {
  box-sizing: border-box;
  font-size: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

body {
  margin: 0;
  padding: 0;

  font-family: var(--font-family-primary);
  font-weight: 400;
  line-height: var(--line-height-m);
  color: var(--color-text-black);
  overflow-x: hidden;
  // background-color: var(--color-violet-2);
}

//img {
//  width: 100%;
//}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 80px;
  @media (max-width: 993px) {
    padding: 0 32px;
  }
  @media (max-width: 577px) {
    padding: 0 16px;
  }
}
