.ConfirmPageBlock {
  margin-top: 10px;

  .buttons {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    gap: 20px;

    & > :first-of-type {
      height: 48px;
      width: 240px;
      background-color: #56ccf2;
    }

    :nth-child(2) {
      height: 48px;
      width: 240px;
      background-color: #eb5757;
    }

    @media (max-width: 699px) {
      & > :first-of-type {
        width: 150px;
      }

      :nth-child(2) {
        width: 150px;
      }
    }
  }

  .buttons button {
    margin: 0;
    color: #ffffff;
    border-radius: 8px;
    font-family: 'Gilroy', 'Mulish', sans-serif;
    font-weight: 500;
    box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);
  }
}
