@import '../../sales/common/styles/variables';
@import '../../sales/common/styles/mixins';

.userEditForm {
  @include flex(column, center);
  max-width: 610px;
  padding: 0 10px;
  margin: 20px auto;

  .ratePlansContainer {
    @include flex(column, center);

    .ratePlansItemsBlock {
      width: 100%;
      padding-top: 10px;
      @include flex(row, space-between, center);
      gap: 10px;
      @media (max-width: 380px) {
        gap: 4px;
      }

      .ratePlanCell {
        @include cellStyle();
        @include flex(row, center, center);
        width: 42px;
      }

      .checked {
        background-color: $blueCell;
      }

      .subs {
        @include cellStyle();
        width: 100px;
        @include flex(row, center, center);
        @media (max-width: 475px) {
          margin-left: auto;
          margin-top: 15px;
          width: 159px;
        }
      }

      .subsError {
        background: #fd9393;
      }
    }
  }
  .buttonContainer {
    padding: 20px 0;
    @include flex(row, space-between, center);
    gap: 10px;
    @media (max-width: 585px) {
      @include flex(column, center, center);
      flex-direction: column-reverse;
    }
  }
}
