:root {
  // error
  --color-error: #e04f46;
  --color-error-1: #eb5757;
  --color-error-2: #d3302f;

  // text
  --color-text-white: #fafafa;
  --color-text-white-tr75: rgba(250, 250, 250, 0.75);
  --color-text-white-tr35: rgba(250, 250, 250, 0.35);
  --color-text-black: #080808;
  --color--text-1: #2d2d2d;
  --color--text-dark: #4f4f4f;
  --color--text-gray: #979797;
  //background
  --color-background-1: #080808;
  --color-background-2: #fafafa;
  --color-background-white: #ffffff;
  --color-background-dark: #1b1a1c;

  // green
  --color-green-1-tr50: #efffbf;
  --color-green-1: #deff80;
  --color-green-3: #a9e722;
  --color-green-4: #9ac941;
  --color-green-5: #6da611;
  --color-green-6: #71ce73;
  --color-green-7: #447708;
  --color-green-9: #264f03;

  // Violet
  --color-violet-1-tr50: #f0e7fd;
  --color-violet-1-tr25: #f5f1fc;
  --color-violet-1-stat: #D0BCFF;
  --color-violet-1-stat-tr-50: rgba(208,188,255, 0.2);
  --color-violet-1: #e7d4ff;
 
  --color-violet-2: #cfa9ff;
  --color-violet-3: #b77eff;
  --color-violet-3-stat: #7f7fd5;
  --color-violet-4: #9f53ff;
  --color-violet-5: #8728ff;
  --color-violet-6: #681ddb;
  --color-violet-7: #4d14b7;
  --color-violet-7-stat: #4a4458;
  --color-violet-8: #360c93;
  --color-violet-8-stat: #381e72;
  --color-violet-9: #150357;


  // Red
  --color-red-light: #ffd8d6;

  // Black
  --color-black-1: #f4f4f4;
  --color-black-2: #eaeaea;
  --color-black-3: #c2c2c2;
  --color-black-4: #858585;
  --color-black-5: #343434;
  --color-black-6: #2a2a2a;
  --color-black-7: #202020;
  --color-black-8: #151515;
  --color-black-9: #080808;
  --color-black-tr75: rgba(8, 8, 8, 0.75);
  --color-black-10: rgba(81, 81, 81, 1);
  --color-black-11: #211f26;
  --color-black-12: #393642;
  --color-black-13: #4e4a59;
  --color-black-14: #312e36;
  --color-black-15: #2b2930;
  // White
  --color-white-0: #ffffff;
  --color-white-1: #f4f4f4;
  --color-white-2: #eaeaea;
  --color-white-3: #e6e0e9;
  --color-white-4: #cac4d0;
  --color-white-6: #938F99;
}
