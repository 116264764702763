@import '../../../../sales/common/styles/variables';
@import '../../../../sales/common/styles/mixins';

.buttonContainer {
  @include flex(row, center, center);
  min-height: 42px;
  gap: 20px;

  .drawsCategoryBtn {
    margin: 0;
    background-color: transparent;
    border: none;
    @include text($gilroyFont, 700, 16px, 16px);
    @include adapt-font(16, 14, 16, 14);
    cursor: pointer;
  }

  .activeButton {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 4px;
      background-color: #c4c4c4;
    }
  }
}
