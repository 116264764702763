@import '../../../../sales/common/styles/variables';
@import '../../../../sales/common/styles/mixins';

.managerInfo {
  @include blockStyle();
  @include flex(row, space-around, center);
  height: 200px;
  width: 100%;
  @media (max-width: 950px) {
    height: 170px;
  }
  @media (max-width: 480px) {
    @include flex(column, space-around, center);
    height: unset;
    gap: 20px;
    padding: 15px 0;
  }

  .wrapperCheckBox {
    display: flex;
    align-items: center;

    .checkBoxIncome {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    .labelIncome {
      font-family: 'Mulish', serif;
      font-size: 10px;
      font-weight: 700;
      color: #9FA2B4;
      text-transform: uppercase;
      letter-spacing: 0.3px;
    }
  }

  .contactsWrapper {
    height: 100%;
    @include flex(column, center, center);
    gap: 15px;

    .contactsHeader {
      @include text($gilroyFont, 700, 20px, 25px);
      @include adapt-font(20, 14, 25, 17);
    }

    .contact {
      @include flex(row, center, center);
      @include text($gilroyFont, 500, 16px, 20px);
      @include cellStyle();
      width: 100%;
      padding: 0 10px;
      pointer-events: none;
    }
    .allowPointerEvent {
      pointer-events: unset;
      cursor: pointer;
    }
  }
}
