@import '../../../common/styles/mixins.scss';
@import '../../../common/styles/variables.scss';

@mixin field {
  height: 5.5rem;
  background-color: var(--color-black-12);
  border-radius: 0.4rem;
  @include text($robotoFont, 400, 16px, 24px, var(--color-white-3));
  text-transform: none;
}

.mainContainer {
  height: 100vh;
  background-color: var(--color-background-dark);
  padding: 0 1.6rem;
  @include flex(column, flex-start, center);
  .passContainer {
    background-color: var(--color-black-11);
    height: 20rem;
    width: 35.4rem;
    border-radius: 1.6rem;
    padding: 2rem 3rem;
    h3 {
      @include text($robotoFont, 400, 24px, 24px, var(--color-white-3));
    }
  }
  .formContainer {
    @include flex(column, flex-start, center);
    gap: 1rem;
    margin-top: 1.6rem;
  }

  .passField {
    @include field();
    input {
      text-align: center;
    }
    &.errorBorder {
      border: 1px solid var(--color-error-1);
    }
  }

  .enterButton {
    @include field();
    &:hover {
      background-color: var(--color-black-13);
    }
  }
}
