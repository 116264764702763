.mainSection {
  border-radius: 0 0 40px 40px !important;
  padding-top: 152px !important;
  padding-bottom: 80px !important;
  @media (max-width: 992px) {
    border-radius: 0 0 32px 32px !important;
    padding-top: 104px !important;
    padding-bottom: 32px !important;
  }
  @media (max-width: 577px) {
    border-radius: 0 0 24px 24px !important;
    padding-top: 88px !important;
    padding-bottom: 16px !important;
  }
}
.container {
  display: flex;
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 0 1 52%;
    @media (max-width: 577px) {
      gap: 24px;
    }
  }
  .btnContainer {
    z-index: 1000;
    display: flex;
    gap: 8px;
    @media (max-width: 577px) {
      flex-wrap: wrap;
    }
  }
}

.bgc {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  img {
    width: 100%;
    z-index: -1;
    position: absolute;
    display: block;
    object-position: left;
    object-fit: cover;
    height: 100%;
  }
  .frame {
    position: relative;
    z-index: 1000;
    border: none;
    width: 100%;
    height: 100%;
  }
}
