.exit {
  background: linear-gradient(90.48deg, #6075ee 0.41%, #9a45e3 99.7%), #9748e4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 5px;
}

.exitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  border: solid 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white), linear-gradient(to right, #00c2ff, #9a45e3 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-left: 50px;
  cursor: pointer;
  @media screen and (max-width: 990px) {
    margin-left: 15px;
  }
}

.registrationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: linear-gradient(90.48deg, #00c2ff 0.41%, #9a45e3 99.7%);
  border-radius: 30px;
  padding: 0 15px;
  cursor: pointer;
}

.registration {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: white;
}

.registrationContainer img {
  padding: 0 5px;
}
.underlineFalse {
  text-decoration: none;
}
.underlineTrue {
  text-decoration: underline;
}

.infoText {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  margin: 0;
  a {
    color: black;
  }
}
.headerContainerBg {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(12.5px);
}

.headerContainer {
  display: flex;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  @media screen and (max-width: 990px) {
    padding: 0 5%;
  }
  @media screen and (max-width: 890px) {
    padding: 0 3%;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 0 40px;
  }

  .items {
    display: flex;
    justify-content: space-between;
  }

  .shownMenu {
    display: flex;
  }

  @media screen and (max-width: 910px) {
    .info {
      width: 200px;
      margin: 0 15px;
    }
  }
  @media screen and (max-width: 810px) {
    .exitContainer {
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 621px) {
    .shownMenu {
      display: none;
    }
    .info {
      display: none;
    }
  }
  @media screen and (min-width: 620.9px) {
    .menu {
      display: none;
    }
  }
}

@media screen and (max-width: 621px) {
  .exitContainer {
    margin: 0;
    width: 100%;
  }
}
