@mixin btnCard {
  display: flex;
  align-items: center;
  margin: 0;

  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.5);
  border: 1.7px solid #01c2ff;
  backdrop-filter: blur(12px);
  border-radius: 30px;

  span {
    color: #01c2ff;
    margin-right: 10px;
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 357px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  border-radius: 25px;
  width: 273px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;

  .image {
    width: 230px;
    height: 230px;
    border-radius: 25px;
    object-fit: cover;
    margin-top: 10px;
  }

  .text {
    padding: 0 8%;
    flex-grow: 1;
    word-break: keep-all;
    font-size: 18px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    line-height: 19px;
  }

  .winner {
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    color: #14b3fc;
    padding: 0 10%;
    word-break: break-all;
  }

  .btn {
    @include btnCard;
  }

  @media screen and (max-width: 582px) {
    flex-grow: 1;
  }
}

.btnCard {
  @include btnCard;

  border: 1px solid #01c2ff;
  position: relative;

  ::before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }
}
