@import '../../styles/variables';
@import '../../styles/mixins';

.balanceHistoryBlock {
  @include flex(column, flex-start, center);
  margin: 0;

  .header {
    @include text($gilroyFont, 700, 24px, 30px);
    margin: 0;
  }

  .headerButtonsWrapper {
    .headerButtons {
      display: flex;
      padding: 10px 0;
      margin: 20px 0 10px;

      @media (max-width: 700px) {
        max-width: 320px;
        overflow-x: auto;
      }

      button {
        margin: 10px;
        border: none;
        background-color: inherit;
        cursor: pointer;
        @include text($gilroyFont, 700, 16px, 19px);
        @include adapt-font(18, 14, 25, 20);
      }

      .headerButtonsActive {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;
          height: 4px;
          background-color: #c4c4c4;
        }
      }
    }
  }
}
