.section {
  padding: 120px 80px;

  @media (max-width: 993px) {
    padding: 64px 32px;
  }
  @media (max-width: 577px) {
    padding: 32px 16px;
  }
  &.largePadding {
    @media (max-width: 993px) {
      padding: 120px 32px;
    }
    @media (max-width: 577px) {
      padding: 64px 16px;
    }
  }
}
