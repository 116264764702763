.root {
  position: absolute;
  z-index: 10000;
  width: 100%;

  .headerContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 32px;
    align-items: center;
    @media (max-width: 992px) {
      padding: 16px 32px;
    }
    @media (max-width: 577px) {
      padding: 16px 16px;
    }

    .btnContainer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
