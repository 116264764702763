@import '../../../sales/common/styles/variables';
@import '../../../sales/common/styles/mixins';

.managerInfoWrapper {
  height: 200px;
  margin-top: 20px;
  @include flex(row, space-between, center);
  gap: 40px;
  @media (max-width: 950px) {
    @include flex(column, space-between, center);
    gap: 20px;
    height: unset;
  }
}
