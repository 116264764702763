.exit {
  background: linear-gradient(90.48deg, #6075ee 0.41%, #9a45e3 99.7%), #9748e4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 5px;
}

.exitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  border: solid 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white), linear-gradient(to right, #00c2ff, #9a45e3 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
}

@media (max-width: 768px) {
  .exit {
    font-size: 14px;
    line-height: 18px;
  }
  .exitContainer {
    width: 80px;
    height: 35px;
  }
}
