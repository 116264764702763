@import '../../styles/variables';
@import '../../styles/mixins';

.headerBackground {
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  @include flex(row, center);

  .headerContainer {
    width: 80%;
    @media (max-width: 768px) {
      width: 95%;
    }
    .headerWrapper {
      @include flex(row, space-between, center);
      height: 80px;
      @media (max-width: 768px) {
        height: 64px;
      }
    }
  }
}
