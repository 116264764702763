@import '../../../common/styles/mixins';
@import '../../../common/styles/variables';

.managerInfoBlock {
  @include flex(row, center, center);
  flex-grow: 1;
  @include blockStyle;
  @media (max-width: 690px) {
    box-shadow: none;
  }
  .managerInfoWrapper {
    width: 100%;
    min-height: 161px;
    flex-wrap: wrap;
    @include flex(row, space-around, center);
    @media (max-width: 690px) {
      gap: 10px;
      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 2;
      }
    }
  }
}
