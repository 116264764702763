@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';

.mailingFormContainer {
  font-size: 16px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  button {
    --max-width: 330px;
    max-width: min(330px, max(300px, var(--max-width)));
  }
}