$gilroyFont: 'Gilroy', sans-serif;
$mulishFont: 'Mulish', sans-serif;
$robotoFont: 'Roboto', sans-serif;

$blockShadow: 0 4px 24px rgba(0, 0, 0, 0.1);
$blockBackdrop: blur(5.5px);
$blockBackground: rgba(255, 255, 255, 0.7);

$cellBackground: #fcfdfe;
$cellBorderRadius: 8px;
$cellBorder: 1px solid #f0f1f7;

$blueCell: #bbddfe;
$redCell: #fca7a7;
$cellPadding: 11px 10px;
$cellMobilePadding: 11px 3px;

$activeLinkColor: #2f80ed;
$cashColor: #219653;
