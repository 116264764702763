@import '../../../../../common/styles/variables';
@import '../../../../../common/styles/mixins';
@import '../../../../../common/styles/CommonStyles.module';

.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;

  .table {
    margin-top: 20px;
    border-collapse: collapse;

    tbody tr {
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background-color: #f6f5f5;
      }
    }

    .dateCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .idCol,
    .emailCol,
    .phoneCol,
    .theamCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;

      @media (max-width: 799px) {
        display: none;
      }
    }

    .sumCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .telegramCountCol {
      @extend .sumCol;
    }

    .moreCol,
    .payCol,
    .paidCol {
      @extend .sumCol;
      @media (max-width: 599px) {
        display: none;
      }
    }

    .id {
      padding: 11px 10px;
      width: 70px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        display: none;
      }
    }

    .email {
      padding: 11px 10px;
      width: 180px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        display: none;
      }
    }

    .phone {
      padding: 11px 10px;
      width: 145px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        display: none;
      }
    }

    .team {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        display: none;
      }
    }

    .paid {
      padding: 11px 10px;
      width: 80px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }

    .sum {
      width: 80px;
      padding: 11px 10px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 80px;
      }

      @media (max-width: 599px) {
        width: 65px;
        padding: $cellMobilePadding;
      }
    }

    .telegram {
      padding: 11px 10px;
      width: 180px;

      a {
        color: #000000;
      }

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 799px) {
        width: 120px;
      }

      @media (max-width: 599px) {
        width: 120px;
        padding: $cellMobilePadding;
      }
    }

    .more {
      width: 170px;
      padding: $cellMobilePadding;
      @media (max-width: 599px) {
        display: none;
      }
    }

    .pay {
      width: 55px;
      padding: $cellMobilePadding;

      button {
        background-color: #eb5858;
        height: 42px;
        border-radius: 8px;
      }

      button:hover {
        background-color: #d54f4f;
      }

      @media (max-width: 599px) {
        display: none;
      }
    }
  }
}
