@import '../../../common/styles/mixins.scss';
@import '../../../common/styles/variables.scss';

.tableContainer {
  div,
  button,
  p {
    @include text($robotoFont, 400, 14px, 24px, var(--color-white-0));
  }
  .table {
    background-color: transparent;
    :global(.MuiTableCell-root) {
      border-bottom: 1px solid #444c55;
      font-size: 1.8rem;
      color: var(--color-white-0);
    }
    td {
      padding-top: 1.06rem;
      padding-bottom: 1.0675rem;
      &:last-child {
        padding-left: 1.6rem;
      }
    }
    tr {
      &:hover {
        background-color: var(--color-black-11);
      }
    }
  }
  tfoot {
    display: flex;
    justify-content: flex-end;

    height: 3.55rem;
    p {
      margin: 0;
    }
    td {
      border-bottom: none;
      min-width: 36rem;
      display: flex;
      div {
        max-width: 36rem;
      }
      button {
        scale: 1.63;
        padding: 0 0.7rem;
      }
    }
    :global(.MuiTablePagination-input) {
      border-bottom: 1px solid var(--color-white-0);
      margin: 0;
      margin-left: 1.45rem;
      :global(.MuiTablePagination-select) {
        padding: 0 4.5rem 0 0;
      }
      svg {
        color: var(--color-white-0);
        scale: 2;
      }
    }
    :global(.MuiTablePagination-toolbar) {
      min-height: 3.55rem;
      padding: 0;
    }
    :global(.Mui-disabled) {
      color: var(--color-black-4);
      border-bottom: none;
    }
  }
}
