@import '../../../common/styles/mixins.scss';
@import '../../../common/styles/variables.scss';

.widgetsPanelContainer {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  gap: 2rem;
  background-color: transparent;
  margin-bottom: 8rem;

  .infoWidgetContainer {
    div,
    p {
      width: 100%;
      line-height: 2.4rem;
    }
    p {
      color: var(--color-white-0);
      line-height: 2.4rem;
    }
    color: var(--color-white-0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 17.5rem;
    background-color: var(--color-black-11);
    cursor: pointer;
    &:hover {
      background-color: var(--color-black-12);
    }
    border-radius: 2rem;
    aspect-ratio: 1/1;
    grid-column: span 5;
  }
  .statisticWidgetContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 17.5rem;
    background-color: var(--color-black-11);
    cursor: pointer;
    &:hover {
      background-color: var(--color-black-12);
    }
    border-radius: 2rem;
    grid-column: span 7;
    &.gender {
      grid-column: span 6;
    }
  }
}
