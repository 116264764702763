@import '../../../../../../common/styles/variables';
@import '../../../../../../common/styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 10px auto;
  padding: 10px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  .formInput {
    background-color: var(--color-background-white);
    border-radius: 8px;
  }
  .formTitle {
    @include text($gilroyFont, 700, 20px, 25px);
  }
  .passwordInputWithTitleBlock {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 16px;
    @media screen and (max-width: 630px) {
      width: 250px;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
}
