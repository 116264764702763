@import '../../../../sales/common/styles/variables';
@import '../../../../sales/common/styles/mixins';

.balanceCard {
  @include blockStyle();
  @include flex(column, center, center);
  gap: 20px;
  min-width: 350px;
  height: 200px;
  @media (max-width: 950px) {
    height: 180px;
  }
  @media (max-width: 480px) {
    min-width: 280px;
  }

  .balance {
    @include text($mulishFont, 700, 20px, 20px);
  }

  .confirmTransferTitle {
    @include text($gilroyFont, 700, 16px, 16px);
    @include adapt-font(16, 14, 16, 14);
    color: $warningColor;
  }
  .confirmButtonWrapper {
    display: flex;
    gap: 10px;
  }
}
