@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';

.transfer {
  @include flex(column, flex-start, center);

  h2 {
    @include text($gilroyFont, 500, 24px, 26px);

    a {
      color: #000000;
    }
  }
}
