@import '../../sales/common/styles/variables';
@import '../../sales/common/styles/mixins';

.promoCodesForm {
  @include flex(column, center, center);
  max-width: 610px;
  padding: 0 10px;
  margin: 20px auto;
  gap: 20px;

  .buttonContainer {
    padding: 20px 0;
    @include flex(row, space-between, center);
    gap: 10px;
    @media (max-width: 585px) {
      @include flex(column, center, center);
      flex-direction: column-reverse;
    }
  }
}
