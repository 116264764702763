@import '../../../common/styles/mixins';
@import '../../../common/styles/variables';

.balanceBlock {
  @include flex(column, flex-start, center);

  @include blockStyle;
  width: 380px;
  height: 400px;

  .balanceHeader {
    @include text($gilroyFont, 700, 32px, 40px);
    margin-top: 62px;

    @media (max-width: 400px) {
      margin-top: 52px;
    }
  }

  .balanceCount {
    @include text($gilroyFont, 700, 32px, 40px);
    margin-top: 40px;
  }

  .button {
    width: 80%;
    height: 38px;
    border-radius: 8px;
    font-family: 'Gilroy', 'Mulish', sans-serif;
    font-weight: 500;
    margin: 100px 0 0 0;
    box-shadow:
      0px 2px 10px rgba(109, 109, 109, 0.25),
      inset 0px 1px 0px rgba(255, 255, 255, 0.5);

    @media (max-width: 400px) {
      margin: 50px 0 0 0;
    }
  }

  @media (max-width: 400px) {
    width: 280px;
    height: 300px;
  }
}
