@import '../../common/styles/mixins';
@import '../../common/styles/variables';

.accordion {
  //text-align: left;
  //@include text($gilroyFont, 500, 16px, 20px);
  //margin: 0;

  .accordionHeader {
    @include text($gilroyFont, 700, 20px, 24px);
    text-align: left;
  }

  & p {
    @include text($gilroyFont, 500, 16px, 20px);
    text-align: left;
    margin: 0;
  }

  & a {
    color: #000000;
  }

  & a:hover {
    text-decoration: none;
  }

  & span {
    @include text($gilroyFont, 500, 16px, 20px);
    text-align: left;
  }

  & ul {
    list-style-type: decimal;
    padding-left: 20px;
  }

  & li {
    margin-bottom: 3px;
  }
}
