.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media (max-width: 769px) {
    gap: 32px;
  }
  @media (max-width: 577px) {
    gap: 24px;
  }
}

.steps {
  display: flex;
  gap: 8px;
  padding: 0 90px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 97px;
  @media (max-width: 993px) {
    padding: 0 48px;
    margin-bottom: 75px;
  }
  @media (max-width: 577px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .line {
    border-radius: 2px;
    flex: 1 1 auto;
    height: 2px;
    background-color: var(--color-background-2);
    @media (max-width: 577px) {
      margin-top: 30px;
      height: 67px;
      width: 2px;
    }
  }
}

.step {
  position: relative;
  .item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @media (max-width: 993px) {
      width: 32px;
      height: 32px;
    }
    @media (max-width: 577px) {
      width: 16px;
      height: 16px;
    }
  }
  .desc {
    position: absolute;
    bottom: -40px;
    @media (max-width: 577px) {
      bottom: -8px;
    }
    top: auto;
    left: 50%;
    transform: translate(-50%, 100%);
    .descText {
      white-space: nowrap;
      span {
        color: inherit;
      }
      @media (max-width: 577px) {
        font-size: 12px;
      }
    }
  }
}
