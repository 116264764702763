.formContainer {
  max-width: 100%;
  padding: 0 70px;
  display: flex;
  justify-content: center;
}

.formWrapper {
  width: 600px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.formTitle {
  margin-top: 77px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
}

.helperTextWrapper {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.helperText {
  color: #9fa2b4;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.helperLink {
  text-decoration: none;
}

.helperLink p {
  color: #3751ff;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .formContainer {
    padding: 0 10px;
  }
  .formTitle {
    margin-top: 0;
  }
}
