@import '../../../../common/styles/mixins';
@import '../../../../common/styles/variables';

.withdrawalsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  .withdrawalsActionBlock {
    @include flex(column, flex-start, center);
    @include blockStyle;
    width: 760px;
    height: 400px;

    @media (max-width: 768px) {
      width: 700px;
      height: 400px;
    }

    @media (max-width: 699px) {
      width: 300px;
      height: 365px;
    }

    .withdrawalsHeader {
      @include text($gilroyFont, 700, 32px, 40px);
      @include adapt-font(32, 20, 32, 22);
      margin-top: 40px;

      @media (max-width: 699px) {
        margin-top: 30px;
      }
    }

    .balanceSent {
      @include text($gilroyFont, 700, 20px, 30px);
      @include adapt-font(20, 20, 16, 22);
      margin-top: 30px;

      @media (max-width: 699px) {
        margin-top: 20px;
      }
    }

    .usernameHeader {
      @include text($gilroyFont, 500, 20px, 30px);
      @include adapt-font(32, 20, 32, 22);
      margin-top: 54px;

      @media (max-width: 699px) {
        margin-top: 48px;
      }
    }

    .username {
      @include blockStyle;
      @include text($gilroyFont, 700, 20px, 24px);
      margin-top: 30px;
      padding: 8px 114px;
      border-radius: 8px;
      border: 1px solid #f0f1f7;

      @media (max-width: 699px) {
        padding: 8px 40px;
        margin-top: 10px;
      }
    }

    .buttons {
      margin-top: 30px;
      display: flex;
      gap: 30px;

      @media (max-width: 699px) {
        margin-top: 12px;
        gap: 10px;
      }
    }

    .buttons button {
      margin: 0;
      color: #ffffff;
      width: 180px;
      height: 48px;
      border-radius: 8px;
      font-family: 'Gilroy', 'Mulish', sans-serif;
      font-weight: 500;
      box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);

      @media (max-width: 699px) {
        width: 120px;
      }
    }

    .password {
      @include text($gilroyFont, 700, 20px, 20px);
      margin-top: 90px;

      @media (max-width: 699px) {
        margin-top: 30px;
      }
    }

    .inputHeader {
      @include text($gilroyFont, 500, 20px, 20px);
      margin-top: 60px;
    }

    .input {
      margin: 20px 0 10px;

      @media (max-width: 699px) {
        margin-bottom: 60px;
      }
    }

    .passwordButtons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 20px;

      & > :first-of-type {
        height: 48px;
        width: 240px;
        background-color: #29af61;
        border-radius: 8px;
      }

      & > :first-of-type:hover {
        background-color: #1f884b;
      }

      :nth-child(2) {
        height: 48px;
        width: 120px;
        background-color: #d33131;
        border-radius: 8px;
      }

      :nth-child(2):hover {
        background-color: #ab2626;
      }

      @media (max-width: 699px) {
        & > :first-of-type {
          width: 160px;
        }

        :nth-child(2) {
          width: 80px;
        }
      }
    }

    .application {
      @include text($gilroyFont, 700, 20px, 20px);
      margin-top: 90px;

      @media (max-width: 699px) {
        margin-top: 30px;
      }
    }

    .applicationText {
      @include text($gilroyFont, 500, 20px, 20px);
      margin-top: 60px;
      width: 90%;
    }

    .applicationButton {
      margin: 108px 0 0;
      color: #ffffff;
      width: 418px;
      height: 48px;
      border-radius: 8px;
      font-family: 'Gilroy', 'Mulish', sans-serif;
      font-weight: 500;
      box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);

      @media (max-width: 699px) {
        margin-top: 80px;
        width: 218px;
      }
    }
  }

  .accordion {
    .accordionHeader {
      @include text($gilroyFont, 700, 20px, 24px);
    }

    & p {
      @include text($gilroyFont, 500, 16px, 20px);
      text-align: left;
      margin: 0;
    }

    & ul {
      list-style-type: decimal;
      padding-left: 20px;
    }

    & li {
      margin-bottom: 3px;
    }
  }
}
