@import '../../styles/mixins';

.typography {
  margin: 0;
  font-family: var(--font-family-primary);
  font-weight: 400;
  line-height: 120%;
  color: var(--color-text-white);

  span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;

    &.green {
      color: var(--color-green-3);
    }
  }

  &.dark {
    color: var(--color-text-black);
  }
}

.title_block {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-size: 80px;
  letter-spacing: 6.4px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(80, 40, 6.4, 1.6);
  }
  @media (max-width: 769px) {
    @include adapt-font-M-S(40, 24, 1.6, 0.96);
  }
}

.title_block_768 {
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-size: 64px;
  letter-spacing: 2.56px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(64, 40, 2.56, 1.6);
  }
  @media (max-width: 769px) {
    @include adapt-font-M-S(40, 24, 1.6, 0.96);
  }
}

.title_primary_1 {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  font-size: 40px;
  letter-spacing: 1.6px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(40, 32, 1.6, 1.28);
  }

  @media (max-width: 769px) {
    @include adapt-font-M-S(32, 24, 1.28, 0.96);
  }
}

.title_primary_2 {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  font-size: 40px;
  letter-spacing: 1.6px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(40, 20, 1.6, 0.8);
  }
  @media (max-width: 769px) {
    @include adapt-font-M-S(20, 14, 0.8, 0.56);
  }
}

.title_secondary {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  font-size: 32px;
  letter-spacing: 1.28px;
  @media (max-width: 1441px) {
    @include adapt-font(32, 14);
  }

  &.bold {
    font-family: var(--font-family-secondary-bold);
  }

  &.nonAdapt {
    font-size: 32px;
  }
}

.header_primary {
  font-size: 32px;
}

.header_secondary {
  font-size: 24px;
  letter-spacing: 0.48px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(24, 16, 0.48, 0.32);
  }

  @media (max-width: 769px) {
    @include adapt-font-M-S(16, 12, 0.32, 0.24);
  }
}

.subheader_primary {
  @include adapt-font(20, 12);
}

.subheader_secondary {
  @include adapt-font-L-M(16, 14, 0.32, 0.28);

  @media (max-width: 769px) {
    @include adapt-font-M-S(14, 10, 0.28, 0.16);
  }

  &.bold {
    font-weight: 700;
  }
  // @include adapt-font(16, 12)
}

.typeface_main {
  @include adapt-font-L-M(14, 12, 0.28, 0.2);
  @media (max-width: 769px) {
    @include adapt-font-M-S(12, 10, 0.28, 0.2);
  }
}

.btn_s {
  text-transform: uppercase;
  font-size: 14px;
}

.btn_m {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.32px;
  @media (max-width: 769px) {
    @include adapt-font-M-S(16, 14, 0.32, 0.28);
  }
}

.btn_l {
  line-height: 120%;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.4px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(20, 16, 0.4, 0.32);
  }
  @media (max-width: 769px) {
    font-size: 16px;
    letter-spacing: 0.32px;
    // @include adapt-font-M-S(16, 14, 0.32, 0.28);
  }

  &.nonAdapt {
    font-size: 20px;
  }
}

.title_any_cads {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  font-size: 24px;
  letter-spacing: 0.96px;
  @media (max-width: 1441px) {
    @include adapt-font-L-M(24, 14, 0.96, 0.56);
  }

  @media (max-width: 769px) {
    font-size: 14px;
    letter-spacing: 0.56px;
  }
}

.big_link {
  font-size: 14px;
}

.statistic {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  font-size: 28px;
}

.typeface_bold {
  @include adapt-font(14, 10);
  font-weight: 700;
  line-height: 130%;
}

.small_text {
  font-size: 14px;
  @media (max-width: 1440px) {
    @include adapt-font-L-M(14, 10, 0.28, 0.2);
  }

  @media (max-width: 769px) {
    @include adapt-font-M-S(10, 8, 0.2, 0.16);
  }

  &.nonAdapt {
    @media (max-width: 1441px) {
      font-size: 10px;
    }
  }
}

.very_small_text {
  font-size: 8px;
}

.small_link {
  font-size: 10px;
  line-height: 130%;
}

.title_side_menu {
  font-size: 10px;
  line-height: 130%;
}

.footer_m {
  font-family: var(--font-family-secondary);
  @include adapt-font-L-M(12, 8, 0.48, 0.32);
  @media (max-width: 769px) {
    font-size: 8px;
    letter-spacing: 0.32px;
  }
}

.footer_ms {
  font-weight: 500;
  font-size: 10px;
}

.footer_s {
  font-family: var(--font-family-secondary);
  font-size: 8px;
}

/*for changeable footer - ContactInfo*/

.footer_xl, .footer_link, .footer_l, .footer_link_xl {
  font-family: var(--font-family-primary);
  line-height: 1.2;
  color: var(--color-white-0);
  font-size: 17px;
}

.footer_link_xl, .footer_link {
  &:visited {
    color: var(--color-white-0);
  }
}

.footer_xl, .footer_link_xl {
  font-weight: 700;
}

.footer_l, .footer_link {
  font-weight: 300;
}

.error {
  @include adapt-font-L-M(12, 8, 0.48, 0.32);
  color: var(--color-error);
}
