.SalesSettingsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 599px) {
      align-items: center;
    }
  }

  .changePassLink {
    text-align: right;
    width: 490px;
    font-size: 15px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 599px) {
      width: 220px;
    }
  }

  .buttons {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    gap: 20px;

    & > :first-of-type {
      height: 48px;
      width: 160px;
      background-color: #56ccf2;

      &:hover {
        background-color: #46aed0;
      }
    }

    :nth-child(2) {
      height: 48px;
      width: 326px;
      background-color: #27ae60;

      &:hover {
        background-color: #209852;
      }
    }

    @media (max-width: 699px) {
      & > :first-of-type {
        display: none;
      }
    }

    @media (max-width: 599px) {
      :nth-child(2) {
        width: 220px;
      }
    }
  }

  .buttons button {
    margin: 0;
    color: #ffffff;
    border-radius: 8px;
    font-family: 'Gilroy', 'Mulish', sans-serif;
    font-weight: 500;
    box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);

    &:disabled {
      opacity: 0.5;
    }
  }
}
