.userControl {
  background-color: var(--color-background-1);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 12px 6px 4px;
  border-radius: 40px;
  svg {
    color: var(--color-background-2);
  }

  .userPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;

    svg {
      transform: translateX(3px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover,
  &:active {
    background-color: var(--color-background-2);

    svg {
      color: var(--color-background-1);
    }

    .name {
      color: var(--color-background-1);
    }
  }

  &.dark {
    border: 1px solid var(--color-background-1);
    background-color: var(--color-background-2);

    svg {
      color: var(--color-background-1);
    }

    .name {
      color: var(--color-background-1);
    }

    &:hover,
    &:active {
      background-color: var(--color-background-1);

      svg {
        color: var(--color-background-2);
      }

      .name {
        color: var(--color-background-2);
      }
    }
  }
}

.notification {
  cursor: pointer;
  flex-shrink: 0;
  background-color: var(--color-background-1);
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  .icon {
    position: relative;
    height: 18px;
    svg {
      display: block;
      color: var(--color-background-2);
    }
    .signal {
      top: 0;
      right: -3px;
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: var(--color-green-3);
      border: 2px solid var(--color-background-1);
      border-radius: 50%;
    }
  }

  &:hover,
  &:active {
    background-color: var(--color-background-2);

    svg {
      color: var(--color-background-1);
    }
    .signal {
      border-color: var(--color-background-2);
    }
  }

  &.dark {
    background-color: var(--color-background-2);
    border: 1px solid var(--color-background-1);
    .icon {
      svg {
        color: var(--color-background-1);
      }
    }
    .signal {
      border-color: var(--color-background-2);
    }
    &:hover,
    &:active {
      background-color: var(--color-background-1);

      svg {
        color: var(--color-background-2);
      }
      .signal {
        border-color: var(--color-background-1);
      }
    }
  }
}
