@import '../../../../../styles/mixins';

.root {
  background-color: var(--color-background-2);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 40px;
  margin: 10px;
  @media (max-width: 993px) {
    padding: 32px;
  }
  @media (max-width: 760px) {
    padding: 24px;
    gap: 24px;
    border-radius: 24px;
  }
}

.header {
  .titleContainer {
    position: relative;
    display: flex;
    gap: 11px;
    align-items: center;
    margin-bottom: 26px;
    @media (max-width: 993px) {
      margin-bottom: 18px;
    }
    @media (max-width: 577px) {
      margin-bottom: 24px;
    }
  }

  .title {
    flex-grow: 1;
    display: block;
    @media (max-width: 1441px) {
      @include adapt-font-L-M(32, 24, 1.28, 0.96);
    }
    @media (max-width: 769px) {
      @include adapt-font-M-S(24, 20, 0.96, 0.8);
    }

    span {
      color: var(--color-violet-4);
    }
  }

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .textContainer {
    max-width: 542px;

    .text {
      display: inline;
    }

    .cookieLink {
      display: inline-block;

      p {
        color: var(--color-violet-4);
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btnContainer {
  display: flex;
  gap: 8px;
  @media (max-width: 1201px) {
    flex-direction: column;
  }
}

.toggleField {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;

  .subtitle {
    @media (max-width: 769px) {
      @include adapt-font-M-S(14, 12, 0.28, 0.24);
    }
  }

  .notActive {
    color: var(--color-black-4);
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--color-black-2);
  }

  .mainLink {
    p {
      color: var(--color-violet-4);
      font-weight: 700;
      font-size: 16px;
    }
  }
}
