.text-slider {
  position: relative;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.slide-enter {
  transform: translateX(200%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms;
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-200%);
  transition: transform 1000ms;
}
.text {
  margin: 0 auto;
  width: 270px;
  height: 120px;
  display: flex;
  @media (max-width: 992px) {
    width: 100%;
  }
}
