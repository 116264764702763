.section {
  display: flex;
  position: relative;
  gap: 8px;

  .bgcRight,
  .bgcLeft {
    position: absolute;
    top: 0;
    height: 100%;
    @media (max-width: 1920px) {
      display: none;
    }
  }

  .bgcRight {
    left: 0;
    width: 40%;
  }

  .bgcLeft {
    right: 0;
    width: 30%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1px;
    padding: 0;
    @media (max-width: 577px) {
      flex-direction: column;
    }
  }

  .right {
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 993px) {
      padding: 40px;
    }
    @media (max-width: 577px) {
      padding: 24px;
    }

    .rightBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        flex: 0 0 35%;
        @media (max-width: 1600px) {
          flex: 0 0 30%;
        }

        @media (max-width: 1201px) {
          display: none;
        }
      }

      .btnContainer {
        display: flex;
        gap: 8px;
        @media (max-width: 760px) {
          margin-top: 24px;
          flex-wrap: wrap;
        }

        button {
          @media (max-width: 760px) {
            flex: 1 1 100%;
          }
        }
      }
    }
  }

  .left {
    flex: 0 0 35%;
    padding: 80px 20px;
    @media (max-width: 992px) {
      flex: 0 0 38%;
      padding-bottom: 100px;
    }
    @media (max-width: 577px) {
      padding-bottom: 107px;
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
