@import '../../styles/variables';
@import '../../styles/mixins';

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 870px) {
    gap: 15px;
  }
  .headerLink {
    text-decoration: none;
    @include text($mulishFont, 700, 16px, 20px);
    @include adapt-font(16, 14, 20, 20);
  }
  .activeHeaderLink {
    @extend .headerLink;
    color: $activeLinkColor;
  }
}
