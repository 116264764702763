@import '../../../../../styles/mixins';

.root {
  background-color: var(--color-background-2);
  border-radius: 0 0 40px 40px;
  @media (max-width: 992px) {
    border-radius: 0 0 32px 32px;
  }
  @media (max-width: 577px) {
    border-radius: 0 0 24px 24px;
  }

  .cookieContainer {
    padding-top: 152px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 80px;
    @media (max-width: 993px) {
      padding-top: 104px;
      padding-bottom: 32px;
    }
    @media (max-width: 577px) {
      gap: 24px;
      padding-top: 88px;
      padding-bottom: 16px;
    }

    .backBtn {
      align-self: start;
      cursor: pointer;
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (max-width: 993px) {
        gap: 16px;
      }

      .main_point {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .privacyPolicyTitle {
        @media (max-width: 769px) {
          @include adapt-font-M-S(40, 20, 1.6, 0.96);
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width: 993px) {
        gap: 32px;
      }
      @media (max-width: 577px) {
        gap: 24px;
      }
    }
  }
}

th {
  padding: 8px;

  &.sectionNumber {
    padding-right: 5px;
    @media (max-width: 993px) {
      padding-right: 1px;
    }
  }

  &.sectionTitle {
    padding-left: 8px;
    @media (max-width: 993px) {
      padding-left: 4px;
    }
    padding-bottom: 16px;
  }

  vertical-align: text-top;
}

.tdWithTable {
  padding-bottom: 0;
}

table {
  margin-top: 8px;
  border-collapse: collapse;

  td {
    padding: 4px 0;
    vertical-align: text-top;
  }

  tr td:last-child {
    padding-left: 8px;
    @media (max-width: 993px) {
      padding-left: 4px;
    }
  }
}

.bodyPoint {
  display: table-row;
  border-spacing: 0 8px;
  padding-bottom: 0;

  tr:last-child {
    padding-bottom: 0;
  }
}

.tableSection {
  margin-top: 0;
}

.pointNumber,
.pointTitle {
  @media (max-width: 769px) {
    @include adapt-font-M-S(14, 12, 0.28, 0.24);
  }
}

.pointNumber {
  color: var(--color-violet-5);
}

.title {
  @media (max-width: 769px) {
    @include adapt-font-M-S(32, 14, 1.28, 0.56);
  }
}
