.block {
  border: 1px solid var(--color-background-1);
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.blockBig {
  width: 5.6rem;
  height: 5.6rem;

  &:hover {
    border: 2px solid var(--color-background-1);
  }

  &:hover .icon {
    width: 3.2rem;
    height: 3.2rem;
  }

  &:active .icon {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.blockSmall {
  width: 4rem;
  height: 4rem;

  &:active {
    border: 2px solid var(--color-background-1);
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}
