.tableHeadContainer {
  th {
    :global(.MuiButtonBase-root),
    :global(.MuiTableSortLabel-root) {
      display: flex;
      font-size: 1.8rem;
      gap: 2.4rem;
      color: var(--color-white-3);
      font-weight: var(--font-weight-bold);
      svg {
        min-width: 1.6rem;
        &:focus {
          outline: none;
        }
      }
      use {
        scale: 2;
      }
    }
  }

  .arrowDown {
    display: flex;
    transform: rotate(180deg) translateX(-2px);
    align-items: flex-start;
  }
}
