.section {
  .container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    @media (max-width: 993px) {
      gap: 32px;
    }
    @media (max-width: 577px) {
      gap: 16px;
    }
  }
  .content {
    .bgcImg {
      display: flex;
      padding: 0 19%;
      justify-content: space-between;
      @media (max-width: 760px) {
        justify-content: center;
      }
      img:first-child {
        @media (max-width: 760px) {
          display: none;
        }
      }
    }
    .cardsContainer {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      @media (max-width: 993px) {
        gap: 8px;
      }
    }
    .bgcImgBottom {
      .ellips {
        transform: scaleY(-1);
      }
    }
  }
  .btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
    @media (max-width: 577px) {
      justify-content: flex-start;
    }
  }
}

.ellips {
  width: 120px;
  height: 60px;
  @media (max-width: 577px) {
    width: 64px;
    height: 32px;
  }
}
