@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';
@import '../../../../common/styles/CommonStyles.module';

.table {
  margin-top: 20px;
  border-collapse: collapse;

  .dateCol {
    @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
    text-transform: uppercase;
    @media (max-width: 700px) {
      display: none;
    }
  }

  .emailCol {
    @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
    text-transform: uppercase;
  }

  .shortCountCol {
    @extend .emailCol;
  }

  .subscribersCountCol {
    @extend .emailCol;
  }

  .telegramCol {
    @extend .emailCol;
    @media (max-width: 1350px) {
      display: none;
    }
  }
  .date {
    padding: 11px 10px;
    width: 162px;

    .commonCell {
      @extend %tableCell;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  .email {
    width: 218px;
    padding: 11px 10px;
    .commonCell {
      @extend %tableCell;
    }

    @media (max-width: 700px) {
      width: 127px;
      padding: $cellMobilePadding;
    }
  }

  .subscription {
    padding: 11px 10px;
    width: 40px;

    .subscriptionCell {
      width: inherit;
      @include flex(row, center, center);
      @include cellStyle();
      background-color: $blueCell;

      span {
        @include text($mulishFont, 400, 14px, 20px, #4b506d);
        opacity: 0.4;
      }
    }

    @media (max-width: 700px) {
      padding: $cellMobilePadding;
    }
  }

  .subscribers {
    padding: 11px 10px;
    width: 157px;

    .subscribersCountCell {
      width: inherit;
      @include flex(row, flex-start, center);
      @include cellStyle();
      padding-left: 5px;

      span {
        padding-left: 10px;
        @include text($mulishFont, 400, 14px, 20px, #000000);
        opacity: 0.4;
      }
    }

    .subscribersCountWarningCell {
      width: inherit;
      @extend .subscribersCountCell;
      background-color: $redCell;

      span {
        padding-left: 10px;
        @include text($mulishFont, 400, 14px, 20px, #000000);
        opacity: 0.4;
      }
    }

    @media (max-width: 700px) {
      width: 102px;
      padding: $cellMobilePadding;
    }
  }

  .telegram {
    width: 254px;
    padding: 11px 10px;
    .commonCell {
      @extend %tableCell;
    }

    @media (max-width: 1350px) {
      display: none;
    }
  }
}
