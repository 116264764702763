@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';

.headerContainer {
  @include flex(row, flex-start);
  width: 100%;
  margin-bottom: 8.4rem;
  @media (max-width: 768px) {
    width: 95%;
  }
  .headerWrapper {
    @include flex(row, space-between, center);
    width: 100%;
    height: 9.6rem;
    @media (max-width: 768px) {
      height: 6.4rem;
    }
  }
}
