.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 769px) {
    gap: 32px;
  }
  @media (max-width: 577px) {
    gap: 24px;
  }
}

.cardsContainer {
  display: flex;
  gap: 22px;
  @media (max-width: 1201px) {
    gap: 16px;
  }
  @media (max-width: 577px) {
    gap: 8px;
  }
  flex-wrap: wrap;

  & > *:not(:first-child) {
    @media (max-width: 577px) {
      flex: 1 1 calc(50% - 4px);
    }
  }

  & > *:first-child {
    @media (max-width: 577px) {
      flex: 1 1 100%;
    }
  }
}
