.dataStatic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  row-gap: 1.6rem;
}

.dataUsers {
  width: 50%;
  flex-direction: row;
  p {
    line-height: 1.4rem;
    margin-bottom: 0.9rem;
  }
  &:nth-child(even) {
    padding-left: 0.9rem;
  }
  &:nth-child(odd) {
    padding-right: 0.9rem;
  }
  .titleRange {
    color: var(--color--text-dark);
  }
  .valueRange {
    color: var(--color--text-gray);
  }
}

.boxStats {
  h2 {
    font-size: 16px;
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--color-white-0)
  }
  .typograhyLinear {
    display: flex;
    justify-content: space-between;
    font-style: italic;
  }
  @media screen and (max-width: 580px) {
    width: 160px;
  }
  @media screen and (max-width: 425px) {
    width: 80%;
    margin: 20px auto;
  }
}

.typograhyLinear {
  p {
    font-size: 12px;
  }
}

.canvas {
  width: 13rem;
  margin: 0 auto;
}
