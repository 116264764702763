@mixin buttonUiKit {
  border-radius: 4rem;
  border: none;
  cursor: pointer;
  gap: 0.8rem;
  display: inline-flex;
  padding: 1.6rem 3.2rem 1.6rem 3.2rem;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  user-select: none;

  .textBtn {
    text-transform: uppercase;
    color: var(--color-text-black);
    transition: color 0.5s;
    z-index: 1;
    user-select: none;
  }
  .icon {
    z-index: 1;
    transition: color 0.5s;
  }

  &.disabled {
    cursor: initial;
  }

  &.medium {
    padding: 1.2rem 3.2rem 1.2rem 3.2rem;
    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
  &.small {
    padding: 0.8rem 2.4rem 0.8rem 2.4rem;
    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    transition:
      width 0.5s,
      height 0.5s cubic-bezier(0.8, 1, 0.8, 1) 0s;
  }
  &:hover::before {
    width: 80rem;
    height: 80rem;
  }
}

.primary {
  @include buttonUiKit;
  background-color: var(--color-green-3);

  &::before {
    background-color: var(--color-background-1);
  }
  &:hover {
    outline: none;
    .textBtn {
      color: var(--color-text-white);
    }
    .icon {
      fill: var(--color-text-white);
    }
  }

  &:active {
    background-color: var(--color-black-1);
    .textBtn {
      color: var(--color-black-3);
    }
    .icon {
      fill: var(--color-black-3);
    }
  }

  &.dark {
    &::before {
      background-color: var(--color-background-2);
    }
    &:active::before {
      background-color: var(--color-black-1);
    }
    &:active,
    &:hover {
      outline: none;
      .textBtn {
        color: var(--color-text-black);
      }
      .icon {
        fill: var(--color-text-black);
      }
    }
  }
}

.secondary {
  @include buttonUiKit;
  outline: 0.1rem solid var(--color-background-1);

  &:hover {
    outline: 0.2rem solid var(--color-background-1);
  }
  &:active {
    outline: 0.2rem solid var(--color-black-5);

    .textBtn {
      color: var(--color-black-5);
    }
  }

  &.dark {
    background-color: var(--color-background-1);
    outline: 0.1rem solid var(--color-background-2);
    .textBtn {
      color: var(--color-text-white);
    }
    .icon {
      fill: var(--color-text-white);
    }

    &:hover {
      outline: 0.2rem solid var(--color-background-2);
    }

    &:active {
      outline: 0.2rem solid var(--color-black-3);
      .textBtn {
        color: var(--color-black-3);
      }
    }
  }
}

.text {
  border-radius: 2.4rem;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0.8rem 2rem 0.8rem 2rem;
  gap: 0.8rem;
  text-transform: uppercase;
  align-items: center;

  .textBtn {
    color: var(--color-black-5);
  }

  &.small {
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
  }

  &:hover {
    background-color: var(--color-black-1);
  }
  &:active {
    background-color: var(--color-violet-1);
  }

  &.dark {
    .textBtn {
      color: var(--color-text-white);
    }
    &:hover {
      background-color: var(--color-black-1);
    }
    &:active {
      background-color: var(--color-violet-1);
    }
    &:hover,
    &:active {
      .textBtn {
        color: var(--color-text-black);
      }
    }
  }

  &.disabled {
    cursor: initial;
    pointer-events: none;
    .textBtn {
      color: var(--color-black-4);
    }
  }
}

.link {
  cursor: pointer;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0.2rem 0;
  position: relative;

  .textBtn {
    text-transform: uppercase;
    text-decoration: none;
  }
  &:disabled {
    cursor: initial;
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 0.1rem;
    background-color: var(--color-background-1);
    bottom: 0.2rem;
    scale: 0;
    transition: scale 0.2s ease-in-out;
  }
  &:active,
  &:hover {
    &:after {
      scale: 1;
      transition: scale 0.2s ease-in-out;
    }
  }

  &.dark {
    .textBtn {
      color: var(--color-text-white);
    }
    &:after {
      background-color: var(--color-background-2);
    }
  }
}

.navigation {
  @include buttonUiKit;
  .textBtn {
    color: var(--color-text-black);
  }
  &:active,
  &:hover {
    background-color: var(--color-background-1);
    .textBtn {
      color: var(--color-text-white);
    }
  }

  &.dark {
    .textBtn {
      color: var(--color-text-white);
    }

    &:active,
    &:hover {
      background-color: var(--color-background-2);
      .textBtn {
        color: var(--color-text-black);
      }
    }
  }
}

.noAnimation {
  &:before {
    content: '';
    display: none;
  }
  &:active,
  &:hover {
    .text {
      color: var(--color-background-2);
    }
  }
}
