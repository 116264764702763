@import 'variables';

@mixin flex($axis: row, $justify-content: flex-start, $align-items: normal) {
  display: flex;
  flex-direction: $axis;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text($family, $weight, $size, $lineHeight, $color: #000000) {
  font-family: $family, sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
  color: $color;
}

@mixin blockStyle() {
  box-shadow: $blockShadow;
  backdrop-filter: $blockBackdrop;
  background: $blockBackground;
  border-radius: 25px;
}

@mixin cellStyle() {
  height: 42px;
  background-color: $cellBackground;
  border-radius: $cellBorderRadius;
  border: $cellBorder;
}

@mixin overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$maxWidth: 1520;
@mixin adapt-font($pcFontSize, $mobFontSize, $pcLineHeight, $mobLineHeight) {
  $addSizeFont: $pcFontSize - $mobFontSize;
  $addSizeLine: $pcLineHeight - $mobLineHeight;
  $maxWidth: $maxWidth - 320;
  font-size: calc(#{$mobFontSize + px} + #{$addSizeFont} * ((100vw - 320px) / #{$maxWidth}));
  line-height: calc(#{$mobLineHeight + px} + #{$addSizeLine} * ((100vw - 320px) / #{$maxWidth}));
}
