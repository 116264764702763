@import '../../../sales/common/styles/variables';
@import '../../../sales/common/styles/mixins';

.drawCard {
  width: 270px;
  min-height: 440px;
  box-shadow: $blockShadow;
  border-radius: $drawCardBorderRadius;
  background-color: $blockBackground;
  padding: 15px;
  @include flex(column, center, center);
  gap: 15px;

  .drawImage {
    max-width: 240px;
    max-height: 240px;
    border-radius: 20px;
    cursor: pointer;
  }

  .drawWinner {
    @include text($gilroyFont, 700, 16px, 120%);
    color: $drawCardEditBtnColor;
  }

  .drawCardTitleWrapper {
    min-height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drawTitle {
    @include text($gilroyFont, 700, 18px, 120%);
    @include flex(row, center, center);
    min-height: 80px;
  }

  .drawCardButtonsWrapper {
    @include flex(column, center, center);
    gap: 10px;

    .drawCardEditBtn {
      margin: 0;
      height: 45px;
      width: 177px;
      border-radius: 30px;
      background-color: transparent;
      @include text($gilroyFont, 700, 16px, 16px);
      color: $drawCardEditBtnColor;
      border: 2px solid $drawCardEditBtnColor;
      cursor: pointer;
    }

    .drawCardHideBtn {
      @extend .drawCardEditBtn;
      color: $drawCardHideBtnColor;
      border: 2px solid $drawCardHideBtnColor;
    }
    .drawCardShowBtn {
      @extend .drawCardEditBtn;
      color: $drawCardEditBtnColor;
      border: 2px solid $drawCardEditBtnColor;
    }
    .drawCardSaveBtn {
      @extend .drawCardEditBtn;
      color: #ffffff;
      border: 2px solid $drawCardSaveBtnColor;
      background-color: $drawCardSaveBtnColor;
    }
    .drawCardConfirmBtn {
      @extend .drawCardHideBtn;
      background-color: $drawCardHideBtnColor;
      color: #ffffff;
    }
  }
}

.disabledCard {
  opacity: 0.5;
}

/* for portal modal*/

.portalModalWrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(96, 96, 96, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
