.winnerContainer {
  width: 100%;
  font-weight: 500;
  font-size: 1.6rem;
  flex: 1 0 auto;
  text-align: left;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .place {
    white-space: nowrap;
    flex: 1 0 10%;
    font-weight: 700;
  }

  .tgName {
    color: #14b3fc;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 0.4rem;
  }
}
