.section {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: 577px) {
      gap: 24px;
    }
    .cardsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: flex-end;
      @media (max-width: 577px) {
        gap: 4px;
      }
    }
  }
}

.longText {
  @media (max-width: 992px) {
    display: none;
  }
}
.shortText {
  display: none;
  @media (max-width: 992px) {
    display: inline;
  }
}
.longTitle {
  @media (max-width: 577px) {
    display: none;
  }
}
.shortTitle {
  display: none;
  @media (max-width: 577px) {
    display: inline;
  }
}
