.overlay {
  position: fixed;
  z-index: 10000;
  inset: 0;

  opacity: 0.7;
  background-color: rgba(8, 8, 8, 0.75);

  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.content {
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //max-width: 637px;
  @media (max-width: 992px) {
    min-width: 70%;
  }
  @media (max-width: 577px) {
    min-width: 96%;
  }
  //min-width: 542px;
  //
  //background-color: var(--color-dark-700);
  //border: 1px solid var(--color-dark-500);
  //box-shadow: hsl(206deg 22% 7% / 35%) 0 10px 38px -10px, hsl(206deg 22% 7% / 20%) 0 10px 20px -15;

  animation: content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlay-show {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes content-show {
  from {
    transform: translate(-50%, -50%) scale(0.3);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
