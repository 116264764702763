.container {
  width: 728px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 10px auto;
  padding: 10px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  .buttonSettingsContainer {
    width: 632px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    @media screen and (max-width: 720px) {
      width: 532px;
      gap: 22px;
    }
    @media screen and (max-width: 630px) {
      flex-wrap: wrap;
      width: 288px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    input {
      width: 300px;
      border-radius: 4px;
      height: 48px;
      box-sizing: border-box;
      align-items: center;
      @media screen and (max-width: 720px) {
        width: 255px;
      }
      @media screen and (max-width: 630px) {
        width: 250px;
      }
    }
  }
}

.linkInputWithTitleBlock {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.buttonTextInputWithTitleBlock {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  @media screen and (max-width: 720px) {
    width: 255px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}