:root {
  font-size: 10px;
  //fonts
  --font-family-primary: Arial, sans-serif;
  --font-family-secondary: 'NeutralFaceRegular', sans-serif;
  --font-family-secondary-bold: 'NeutralFaceBold', sans-serif;

  // line heights
  --line-height-l: 120%;

  // font sizes
  //--font-size-xs: 24px;
  //--font-size-s: 28px;
  //--font-size-m: 32px;
  //--font-size-l: 40px;
  //--font-size-xl: 64px;
  //--font-size-xxl: 80px;

  //fonts UiKit
  --font-family-header: Neutral Face;
  --font-family-text: Arial;

  // font sizes UiKit
  --font-size-xxxs: 0.8rem;
  --font-size-xxs: 1rem;
  --font-size-xs: 1.2rem;
  --font-size-s: 1.4rem;
  --font-size-m: 1.6rem;
  --font-size-l: 2rem;
  --font-size-xl: 2.4rem;
  --font-size-xxl: 2.8rem;
  --font-size-xxxl: 3.2rem;
  --font-size-mega: 4rem;
  --font-size-megaL: 4.8rem;
  --font-size-megaXL: 6.4rem;
  --font-size-megaXXL: 8rem;

  // line heights UiKit
  --line-height-m: 120%;
  --line-height-xl: 130%;

  // font weights UiKit
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --size-letter-spacing: 0.03rem;
}

/*:root {
  --font-family-header: Neutral Face;
  --font-family-text:  Arial;



  // font sizes
  --font-size-xs: 10px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 24px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 32px;
  --font-size-mega: 40px;
  --font-size-megaPlus: 80px;

  // line heights
  --line-height-m: 120%;
  --line-height-l: 130%;

  // font weights
  --font-weight-regular: 400;
  --font-weight-bold: 700;
}*/
