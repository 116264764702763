@import '../../../styles/variable';

@mixin typography($fontSize, $fontFamily) {
  margin: 0;
  font-size: $fontSize;
  font-family: $fontFamily;
  line-height: var(--line-height-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--size-letter-spacing);
  color: var(--color-text-black);
}

.blockOrSectionName {
  @include typography(var(--font-size-megaXXL), var(--font-family-header));
}

.titlePrimary {
  @include typography(var(--font-size-mega), var(--font-family-header));
}

.titleSecondary {
  @include typography(var(--font-size-xxxl), var(--font-family-header));
}

.headerPrimary {
  @include typography(var(--font-size-xxxl), var(--font-family-header));
}

.headerSecondary {
  @include typography(var(--font-size-xl), var(--font-family-header));
}

.subheaderPrimary {
  @include typography(var(--font-size-l), var(--font-family-text));
}

.subheaderSecondary {
  @include typography(var(--font-size-m), var(--font-family-text));
}

.subheaderSecondaryBold {
  @include typography(var(--font-size-m), var(--font-family-text));
  font-weight: var(--font-weight-bold);
}

.typefaceMain {
  @include typography(var(--font-size-s), var(--font-family-text));
  line-height: var(--line-height-xl);
}

.buttonS {
  @include typography(var(--font-size-s), var(--font-family-text));
}

.buttonM {
  @include typography(var(--font-size-m), var(--font-family-text));
}

.buttonL {
  @include typography(var(--font-size-l), var(--font-family-text));
}

.titleAnyCads {
  @include typography(var(--font-size-xl), var(--font-family-header));
}

.bigLink {
  @include typography(var(--font-size-s), var(--font-family-header));
  cursor: pointer;
  text-decoration: underline;
  line-height: var(--line-height-xl);
}

.statisticsMore1M {
  @include typography(var(--font-size-xxl), var(--font-family-header));
}

.typefaceMainBold {
  @include typography(var(--font-size-s), var(--font-family-text));
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-bold);
}

.smallText {
  @include typography(var(--font-size-xxs), var(--font-family-text));
  line-height: var(--line-height-xl);
}

.smallLink {
  @include typography(var(--font-size-xxs), var(--font-family-text));
  cursor: pointer;
  text-decoration: underline;
  line-height: var(--line-height-xl);
}

.titleSideMenu {
  @include typography(var(--font-size-xxs), var(--font-family-text));
  line-height: var(--line-height-xl);
}

.footer {
  @include typography(var(--font-size-xs), var(--font-family-text));
}

@media screen and (max-width: $screen-size-average) {
  .blockOrSectionName {
    @include typography(var(--font-size-megaXL), var(--font-family-header));
  }
  .titlePrimary {
    @include typography(var(--font-size-xxxl), var(--font-family-header));
  }
  .titleSecondary,
  .headerPrimary,
  .headerSecondary {
    @include typography(var(--font-size-xl), var(--font-family-header));
  }
  .subheaderPrimary {
    @include typography(var(--font-size-m), var(--font-family-text));
  }
  .subheaderSecondary {
    @include typography(var(--font-size-s), var(--font-family-text));
  }
  .subheaderSecondaryBold {
    @include typography(var(--font-size-s), var(--font-family-text));
    font-weight: var(--font-weight-bold);
  }
  .typefaceMain,
  .buttonS,
  .buttonM,
  .buttonL {
    @include typography(var(--font-size-xs), var(--font-family-text));
  }
  .titleAnyCads,
  .bigLink,
  .statisticsMore1M {
    @include typography(var(--font-size-m), var(--font-family-header));
  }
  .typefaceMainBold {
    @include typography(var(--font-size-xs), var(--font-family-text));
  }
  .smallText,
  .smallLink,
  .titleSideMenu {
    @include typography(var(--font-size-xxs), var(--font-family-text));
  }
  .footer {
    @include typography(var(--font-size-xxxs), var(--font-family-text));
  }
}

@media screen and (max-width: $screen-size-small) {
  .blockOrSectionName {
    @include typography(var(--font-size-mega), var(--font-family-header));
  }
  .titlePrimary {
    @include typography(var(--font-size-xl), var(--font-family-header));
  }
  .titleSecondary,
  .headerPrimary,
  .headerSecondary {
    @include typography(var(--font-size-s), var(--font-family-header));
  }
  .subheaderPrimary {
    @include typography(var(--font-size-xs), var(--font-family-text));
  }
  .subheaderSecondary {
    @include typography(var(--font-size-xs), var(--font-family-text));
  }
  .subheaderSecondaryBold {
    @include typography(var(--font-size-xs), var(--font-family-text));
    font-weight: var(--font-weight-bold);
  }
  .typefaceMain,
  .buttonS,
  .buttonM,
  .buttonL {
    @include typography(var(--font-size-xxs), var(--font-family-text));
  }
  .titleAnyCads,
  .bigLink,
  .statisticsMore1M {
    @include typography(var(--font-size-xs), var(--font-family-header));
  }
  .typefaceMainBold {
    @include typography(var(--font-size-xxs), var(--font-family-text));
  }
  .smallText,
  .smallLink,
  .titleSideMenu {
    @include typography(var(--font-size-xxxs), var(--font-family-text));
  }
  .footer {
    @include typography(var(--font-size-xxxs), var(--font-family-text));
  }
}
