.container {
  width: 728px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 10px auto;
  padding: 10px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }

  .titleTextAreaBlock {
    width: 366px;
    height: 19px;
    margin-bottom: 15px;
    @media screen and (max-width: 630px) {
      text-align: center;
      width: 237px;
      height: 38px;
    }
  }

  .textareaContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .textarea{
    width: 70%;
    border: none;
    div {
      padding: 0
    }
    textarea {
      padding: 8px;
      background-color: rgb(255, 255, 255);
    }
  }
}