.checkEmailContainer {
  max-width: 100%;
  padding: 0 70px;
  display: flex;
  justify-content: center;
}

.checkEmailWrapper {
  max-width: 400px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.checkEmailImg {
  width: 150px;
  margin: 20px 0;
}

.infoMessage {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 600px) {
  .checkEmailContainer {
    padding: 0 24px;
  }
}
