/* eslint-disable */

@font-face {
  font-family: 'Neutral Face';
  src: url('assets/fonts/neutralFaceUiKit.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial';
  src: url('assets/fonts/arialUiKit.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('assets/fonts/nunitoSans.ttf') format('truetype');
}
