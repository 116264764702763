.headerContainer {
  display: flex;
  /*width: 80%;*/
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  /*margin: 0 auto;*/
}
.logo {
  cursor: pointer;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 158px;
  margin-right: 50px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.exit {
  background: linear-gradient(90.48deg, #6075ee 0.41%, #9a45e3 99.7%), #9748e4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 5px;
}

.exitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  border: solid 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white), linear-gradient(to right, #00c2ff, #9a45e3 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
}

.items {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
}

.circle {
  width: 24px;
}

.star {
  height: 17px;
}

.settings {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
}

ul > li > .settings {
  width: 100%;
}

.underlineTrue {
  text-decoration: underline;
}

.underlineFalse {
  text-decoration: none;
}

.shownMenu {
  display: flex;
  gap: 40px;
}

@media screen and (max-width: 644.9px) {
  .shownMenu {
    display: none;
  }

  .exitContainer {
    margin-left: 0;
  }

  .settings {
    margin-left: 10px;
  }

  .headerContainer {
    padding: 0 7%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 645px) {
  .menu {
    display: none;
  }
}
