@import '../../../sales/common/styles/mixins';
@import '../../../sales/common/styles/variables';
@import '../../../sales/common/styles/CommonStyles.module';

.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;

  .table {
    margin-top: 20px;
    border-collapse: collapse;

    .dateCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
      @media (max-width: 570px) {
        display: none;
      }
    }

    .idCol, .subscribersCountCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
      @include flex(row, center, center);
      gap: 5px;
      .sort_icon {
        width: 25px;
        cursor: pointer;
      }
    }

    .emailCol {
      @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
      text-transform: uppercase;
    }

    .subscriptionCol {
      @extend .emailCol;
    }

    .editCol {
      @extend .emailCol;
      @media (max-width: 775px) {
        display: none;
      }
    }

    .date {
      padding: 11px 10px;
      width: 135px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 905px) {
        width: 70px;
      }
      @media (max-width: 570px) {
        display: none;
      }
    }

    .id {
      padding: 11px 10px;
      width: 85px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 570px) {
        width: 48px;
        padding: $cellMobilePadding;
      }
    }

    .email {
      width: 200px;
      padding: 11px 10px;

      .commonCell {
        @extend %tableCell;
      }

      @media (max-width: 855px) {
        width: 120px;
      }
      @media (max-width: 570px) {
        width: 120px;
        padding: $cellMobilePadding;
      }
    }

    .subscription {
      padding: 11px 10px;
      width: 40px;

      .subscriptionCell {
        width: inherit;
        @include flex(row, center, center);
        @include cellStyle();
        background-color: $blueCell;

        span {
          @include text($mulishFont, 400, 14px, 20px, #4b506d);
          opacity: 0.4;
        }
      }

      @media (max-width: 570px) {
        padding: $cellMobilePadding;
      }
    }

    .subscribers {
      padding: 11px 10px;
      width: 125px;

      .subscribersCountCell {
        width: inherit;
        @include flex(row, flex-start, center);
        @include cellStyle();
        padding-left: 5px;

        span {
          padding-left: 10px;
          @include text($mulishFont, 400, 14px, 20px, #000000);
        }
      }

      .subscribersCountWarningCell {
        width: inherit;
        @extend .subscribersCountCell;
        background-color: $redCell;

        span {
          padding-left: 10px;
          @include text($mulishFont, 400, 14px, 20px, #000000);
          opacity: 0.4;
        }
      }

      @media (max-width: 775px) {
        width: 102px;
      }
      @media (max-width: 570px) {
        width: 63px;
        padding: $cellMobilePadding;
      }
    }

    .edit {
      width: 170px;
      padding: $cellMobilePadding;
      @media (max-width: 775px) {
        display: none;
      }
    }
  }
}
