@import '../../../common/styles/mixins';
@import '../../../common/styles/variables';

.checkEmailContainer {
  max-width: 100%;
  display: flex;
  justify-content: center;

  .checkEmailWrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .checkEmailImg {
      width: 150px;
      margin: 20px 0;
    }

    .infoMessage {
      @include text($gilroyFont, 500, 16px, 22px);
    }

    .button button {
      height: 48px;
      width: 350px;
      background-color: #56ccf2;
      margin-top: 10px;
      color: #ffffff;
      border-radius: 8px;
      font-family: 'Gilroy', 'Mulish', sans-serif;
      font-weight: 500;
      box-shadow: 0px 4px 12px 0px rgba(55, 81, 255, 0.24);

      @media (max-width: 399px) {
        width: 250px;
      }
    }
  }
}
