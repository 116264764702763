@import '../../../src/common/styles/mixins';

.container {
  background-color: var(--color-background-dark);
  min-height: 100vh;
  height: min-content;
  padding: 0 3rem;

  .main {
    max-width: 108rem;
    margin: 0 auto;
    padding: 0 10rem;

    div,
    button,
    p {
      @include text($robotoFont, 400, 14px, 24px, var(--color-white-0));
    }
  }
}
