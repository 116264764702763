.selectorMenuContainer {
  display: flex;
  gap: 2rem;
}
.menuDateContainer {
  position: relative;
  height: 41rem;
  width: 32.8rem;
  .fieldsContainer {
    padding: 0 1.8rem;
  }
  .periodContainer {
    .titleAndCalendar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.secondPeriod {
      padding-top: 3.6rem;
    }
    p {
      font-size: var(--font-size-xxxl);
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .dateFields {
      display: flex;
      column-gap: 1.2rem;
      margin-bottom: 1.6rem;
      padding: 0 0.6rem;
    }
  }

  .buttonsContent {
    display: flex;
    justify-content: flex-end;
    gap: 0.8rem;
    padding: 0 1.2rem;
    margin-top: 2.5rem;
    position: relative;
  }
}
