@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';
@import '../../../../common/styles/CommonStyles.module';

.table {
  margin-top: 20px;
  border-collapse: collapse;

  .dateCol {
    @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
    text-transform: uppercase;
    @media (max-width: 700px) {
      display: none;
    }
  }

  .emailCol {
    @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
    text-transform: uppercase;
  }

  .clientsCol {
    @extend .emailCol;
  }

  .incomeCol {
    @extend .emailCol;
  }

  .telegramCol {
    @extend .emailCol;
    @media (max-width: 1350px) {
      display: none;
    }
  }

  .date {
    padding: $cellPadding;
    width: 162px;

    .commonCell {
      @extend %tableCell;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  .email {
    padding: $cellPadding;
    width: 143px;

    .commonCell {
      @extend %tableCell;
    }

    @media (max-width: 700px) {
      width: 127px;
      padding: $cellMobilePadding;
    }
  }

  .clients {
    padding: $cellPadding;
    @extend .email;
    width: 112px;
    @media (max-width: 700px) {
      width: 72px;
      padding: $cellMobilePadding;
    }
  }

  .income {
    padding: $cellPadding;
    @extend .email;
    width: 112px;
    @media (max-width: 700px) {
      width: 72px;
      padding: $cellMobilePadding;
    }
  }

  .telegram {
    padding: $cellPadding;
    @extend .email;
    width: 254px;
    @media (max-width: 1350px) {
      display: none;
    }
  }
}
