@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';

.container {
  @include text($gilroyFont, 500, 20px, 25px);
  @include adapt-font(20, 16, 25, 20);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F8F8F8;

  .buttonsGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 54px;
    margin-top: 24px;
    :first-of-type {
      flex-basis: 50%
    }
    @media screen and (max-width: 466px) {
      row-gap: 24px;
      :first-of-type {
        flex-basis: 80%
      }
      :last-of-type {
        flex-basis: 80%
      }
    }
  }
}