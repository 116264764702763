@import '../../../sales/common/styles/mixins';
@import '../../../sales/common/styles/variables';

.successMessageContainer {
  box-shadow: $blockShadow;
  min-height: calc(100vh - 80px);
  @include flex(column, center, center);
  gap: 15px;
  .successIcon {
    width: 200px;
  }
  .successMessage {
    @include text($gilroyFont, 500, 20px, 20px);
  }
}
