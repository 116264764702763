.section {
  position: relative;
  padding: 120px 0;
  background-color: var(--color-background-2);
  border-radius: 40px;
  &.dark {
    background-color: var(--color-background-1);
  }
  @media (max-width: 992px) {
    padding-top: 64px;
    padding-bottom: 32px;
    border-radius: 32px;
  }
  @media (max-width: 577px) {
    padding-top: 32px;
    padding-bottom: 16px;
    border-radius: 24px;
  }
}
