.wrapper {
  height: 4rem;
  width: 4.1rem;
  border-radius: 0 10rem 10rem 0;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  display: flex;
  cursor: pointer;
}

.logoMobile {
  position: relative;
  z-index: 2;
  left: 0.1rem;
}

.logoTelegivCom {
  position: relative;
  z-index: 1;
  left: -4rem;
}

.wrapper:hover {
  width: 19rem;
}

.wrapper:hover .logoMobile {
  opacity: 0;
}

.wrapper:not(:hover) {
  transition: width 0.4s ease-in-out;
}

.wrapper:not(:hover) .logoMobile {
  transition-delay: 0.3s;
}
