@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins';

.table {
  margin-top: 20px;
  border-collapse: collapse;

  .dateCol {
    @include text($mulishFont, 700, 12px, 15px, #9fa2b4);
    text-transform: uppercase;
  }

  .statusCol {
    @extend .dateCol;
  }

  .incomeCol {
    @extend .dateCol;
  }

  .incomeCol {
    @extend .dateCol;
  }

  .dateRow {
    padding: 11px 10px;
    width: 164px;

    .tableCell {
      width: inherit;
      @include flex(row, flex-start, center);
      @include cellStyle();

      span {
        padding-left: 10px;
        @include text($mulishFont, 500, 14px, 20px);
        @include overflow();
      }
    }

    @media (max-width: 700px) {
      width: 100px;
    }

    @media (max-width: 399px) {
      width: 130px;
    }
  }

  .statusRow {
    padding: 11px 10px;
    width: 164px;

    .tableCellShortCount {
      width: inherit;
      @include flex(row, center, center);
      @include cellStyle();

      span {
        @include text($mulishFont, 400, 14px, 20px);
      }
    }

    @media (max-width: 700px) {
      padding: $cellMobilePadding;
      width: 100px;
    }
  }

  .incomeRow {
    width: 60px;
    padding: $cellMobilePadding;

    .tableCell {
      width: inherit;
      @include flex(row, flex-start, center);
      @include cellStyle();

      span {
        padding-left: 10px;
        @include text($mulishFont, 500, 14px, 20px);
        @include overflow();
      }
    }

    @media (max-width: 399px) {
      width: 70px;
    }
  }
}

.emptyTable {
  opacity: 0.2;
}

.noHistoryTextWrapper {
  position: relative;

  .circularProgress {
    height: 600px;
  }

  .noHistoryText {
    font-size: 30px;
    @include adapt-font(30, 16, 25, 20);
    line-height: 1px;
    position: relative;
    top: 100px;
    z-index: 1;
  }
}
