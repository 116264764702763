.footer {
  background-color: var(--color-violet-2);
  padding: 48px 0;
  @media (max-width: 993px) {
    padding: 32px 0;
  }
  @media (max-width: 577px) {
    padding-top: 16px;
    padding-bottom: 24px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .top {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 80px;

    @media (max-width: 993px) {
      margin-bottom: 24px;
    }
    @media (max-width: 577px) {
      margin-bottom: 40px;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    @media (max-width: 760px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .social {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;

      .socialBlock {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: -6px;

        .item {
          padding: 6px;
        }
      }
    }

    .right {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 577px) {
        flex-direction: column;
      }

      .linksBlock {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        @media (max-width: 760px) {
          gap: 8px;
          justify-content: flex-start;
        }
        @media (max-width: 577px) {
          align-self: start;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 40px;
        }
        margin-right: calc(37px + (80 - 37) * ((100vw - 768px) / (1440 - 768)));
      }

      .authors {
        display: flex;
        justify-content: space-between;
        @media (max-width: 577px) {
          width: 100%;
        }
      }
    }
  }
}

.longText {
  display: inline;
  margin: 0;
  @media (max-width: 1300px) {
    display: none;
  }
  @media (max-width: 577px) {
    display: inline;
  }
}

.copy {
  cursor: initial;

  &:after {
    display: none;
  }
}
