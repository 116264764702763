.loaderContainer {
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
}

.loader {
  font-size: 48px;
  color: #000;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  position: relative;
}

.loader:after {
  content: '';
  position: relative;
  z-index: 5;
}

.loader:before {
  content: '';
  height: 10px;
  width: 100%;
  position: absolute;
  bottom: -27px;
  background: linear-gradient(#a9e722 100%, transparent 0) no-repeat;
  background-size: 0 auto;
  animation: 2000ms lineGrow linear infinite;
}

@keyframes lineGrow {
  0%,
  100% {
    background-size: 0 auto;
  }
  50% {
    background-size: 100% auto;
  }
}
