.root {
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: var(--color-background-2);
  gap: 24px;
  border-radius: 40px;
  @media (max-width: 1201px) {
    border-radius: 24px;
    gap: 16px;
  }
  @media (max-width: 577px) {
    border-radius: 16px;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-radius: 50%;
    background-color: var(--color-violet-3);
    @media (max-width: 1201px) {
      padding: 16px;
    }
    svg {
      @media (max-width: 1201px) {
        width: 24px;
        height: 24px;
      }
      * {
        fill: var(--color-background-2);
      }
    }
  }

  &.dark {
    background-color: var(--color-violet-1);
    .img {
      background-color: var(--color-background-2);
      svg {
        * {
          fill: var(--color-background-1);
        }
      }
    }
  }

  &.aboutUs {
    flex: 0 0 calc(50% - 4px);
    border: 1px solid var(--color-background-1);
    padding: 8px;
    border-radius: 24px;
    flex-direction: row;
    @media (max-width: 992px) {
      gap: 8px;
    }
    @media (max-width: 760px) {
      flex: 0 0 100%;
    }
    @media (max-width: 577px) {
      padding: 4px;
    }

    .img {
      padding: 18px;
      background-color: var(--color-violet-1);
      border-radius: 12px;
      @media (max-width: 992px) {
        padding: 14px;
      }
      @media (max-width: 577px) {
        padding: 7px;
      }
      svg {
        height: 28px;
        width: 28px;
        @media (max-width: 992px) {
          height: 20px;
          width: 20px;
        }
        @media (max-width: 577px) {
          height: 16px;
          width: 16px;
        }
        * {
          fill: var(--color-background-1);
        }
      }
    }
    &.dark {
      border: 1px solid var(--color-background-2);
      background-color: var(--color-background-1);
      .img {
        background-color: var(--color-background-2);
      }
    }
  }

  &.howWork {
    flex: 0 0 calc(25% - 12px);
    padding: 40px 24px;
    @media (max-width: 1201px) {
      padding: 16px 8px 45px 8px;
    }
    @media (max-width: 993px) {
      flex: 0 0 calc(25% - 6px);
    }
    @media (max-width: 760px) {
      flex: 0 0 calc(50% - 4px);
    }
    @media (max-width: 577px) {
      padding-bottom: 16px;
    }

    .img {
      @media (max-width: 577px) {
        padding: 12px;
      }
    }
  }

  &.pluses {
    flex: 0 0 calc(100% / 3 - 44px / 3);
    padding: 40px 40px;
    border: 1px solid var(--color-violet-1);
    @media (max-width: 1201px) {
      padding: 40px 24px 32px 24px;
      flex: 0 0 calc(100% / 3 - 32px / 3);
    }
    @media (max-width: 577px) {
      gap: 12px;
      padding: 16px;
    }
    .img {
      flex: 0 0 auto;
    }
  }
}

.title {
  white-space: nowrap;
  color: var(--color-green-3);
}
