/*COMMUN CONTAINER*/

.container {
  width: 728px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 10px auto;
  padding: 10px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.containerAdvt {
  border-radius: 1.5rem;
  background: #f8f8f8;
  margin: 0 auto 4rem auto;
  max-width: 85.6rem;
  padding: 2rem 0;
}
.subTitle {
  margin: 0;
}
/*CreateDraw component*/

.containerCreateDraw {
  font-size: 16px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

/*AddAvatar in CreateDraw*/

.titleAvatarBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.photoUpload {
  width: 250px;
  height: 250px;
  border-radius: 15px;
  object-fit: cover;
}

.containerAvatar {
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 30px;
}

.avatarError {
  color: red;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  position: absolute;
  top: 245px;
}

.emptyAvatar {
  width: 250px;
  height: 250px;
  border: 2px dashed #919191;
  margin: 0 auto;
  border-radius: 15px;
  display: inline-block;
}

.addPhotoBtnContainer {
  width: 250px;
  height: 48px;
}

.addPhotoBtn {
  width: 100%;
  height: 100%;
  font-family: Gilroy, sans-serif;
  line-height: 19.09px;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
}

/*DataPicker and TimePicker in CreateDraw*/

.titleDataTimePickersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dataAndTimePickersContainer {
  width: 632px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 22px;
  @media screen and (max-width: 720px) {
    justify-content: center;
    gap: 0;
  }
  @media screen and (max-width: 630px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.dataAndTimePickerBlock {
  width: 300px;
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.dataPicker {
  width: 223px;
  height: 48px;
  @media screen and (max-width: 720px) {
    width: 173px;
  }
}

/*до открытия pop-up*/
.timePicker {
  margin-left: 5px;
}

.timePicker input {
  width: 69px;
  height: 48px;
  background-color: inherit;
  outline-color: #1976d2;
  font-size: 16px;
  text-align: center;
}

.timePicker input:hover {
  border-color: black;
}

.timePicker span {
  width: 100%;
}

.timePicker a {
  display: none;
}

.popUp {
  padding-top: 4px;
}

.popUp div input {
  text-align: center;
  font-size: 16px;
  margin: 0;
}

.popUp div + div:last-child div::-webkit-scrollbar {
  background-color: inherit;
  display: none;
}

.popUp div + div:last-child div > ul li {
  padding-left: 18px;
  font-size: 16px;
}

.popUp div + div:last-child div > ul li:hover {
  background-color: #d2ebf1;
}

/*AddItems in CreateDraw*/

.addItemTitleContainer {
  margin-bottom: 0;
}

.addElementContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/*AddElement in AddItems in CreateDraw*/

.editableSpansContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.addInput {
  width: 632px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  @media screen and (max-width: 720px) {
    width: 532px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.addInput input {
  width: 632px;
  height: 48px;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 20px;
  @media screen and (max-width: 720px) {
    width: 532px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.addInput p {
  top: 20px;
}

.addAndDeleteBtnsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 720px) {
    width: 532px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.addItemBtnContainer {
  width: 250px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding-left: 15px;
  @media screen and (max-width: 630px) {
    width: 145px;
    padding-left: 0;
  }
}

.addItemBtn {
  align-items: flex-start;
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #2688eb;
  font-weight: 700;
  text-decoration: none;
  margin: 0;
  @media screen and (max-width: 630px) {
    font-size: 10px;
  }
}

.addItemBtn:hover {
  cursor: pointer;
}

.deleteItemBtnContainer {
  width: 160px;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  padding-right: 15px;
  @media screen and (max-width: 630px) {
    width: 81px;
    padding-right: 0;
  }
}

.deleteItemBtn {
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #2688eb;
  font-weight: 700;
  text-decoration: none;
  @media screen and (max-width: 630px) {
    font-size: 10px;
  }
}

/*EditableSpan in AddElement in AddItems in CreateDraw*/
.deleteItemBtn:hover {
  cursor: pointer;
}

.editableSpan {
  padding-bottom: 10px;
}

.editableSpan input {
  width: 632px;
  min-width: 250px;
  height: 48px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 16px;

  @media screen and (max-width: 720px) {
    width: 532px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

/*ContactData in CreateDraw*/
.contactDataContainer {
  width: 632px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 720px) {
    width: 532px;
    gap: 22px;
  }
  @media screen and (max-width: 630px) {
    flex-wrap: wrap;
    width: 288px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.inputTitle {
  align-items: center;
}

/*phone block in ContactData*/
.phoneInputWithTitleBlock {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

/*width (inputPhone 244px + flag 56px) = 300px*/
.inpPhone input {
  width: 244px;
  height: 48px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  @media screen and (max-width: 720px) {
    width: 199px;
  }
  @media screen and (max-width: 630px) {
    width: 194px;
  }
}

.MuiTelInput {
  font-size: 16px !important;
}

.Flag {
  background-color: black !important;
}

/*telegram block in ContactData*/
.tgInputWithTitleBlock {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  @media screen and (max-width: 720px) {
    width: 255px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.tgInputWithTitleBlock input {
  width: 300px;
  border-radius: 4px;
  height: 48px;
  box-sizing: border-box;
  align-items: center;
  @media screen and (max-width: 720px) {
    width: 255px;
  }
  @media screen and (max-width: 630px) {
    width: 250px;
  }
}

.textFieldTg {
  font-size: 16px;
}

/*CountrySelection in CreateDraw */
.countrySelectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.titleCountrySelectionBlock {
  width: 366px;
  height: 19px;
  margin-bottom: 15px;
  @media screen and (max-width: 630px) {
    text-align: center;
    width: 237px;
    height: 38px;
  }
}

.countriesBlock {
  width: 632px;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 720px) {
    width: 532px;
  }
  @media screen and (max-width: 630px) {
    width: 280px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0;
    padding: 0;
  }
}

.countriesBlock label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnAllCountries {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #fff;
  color: #7c7a7a;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  box-shadow: 0.2em 0.2em 3px rgba(175, 175, 175, 0.2);
  transition: 0.5s;
  width: 116px;
  @media screen and (max-width: 768px) {
    width: 80px;
    font-size: 12px;
  }
}

.btnCountry {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #fff;
  color: #7c7a7a;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  box-shadow: 0.2em 0.2em 3px rgba(175, 175, 175, 0.2);
  transition: 0.5s;
  width: 96px;
  @media screen and (max-width: 768px) {
    width: 80px;
    font-size: 12px;
  }
}

.btnCountry:hover {
  background: #dad7d7;
}

.btnCountrySelected {
  background: #b8caed;
  color: #ffffff;
}

.btnCountrySelected:hover {
  background: #769fe7;
}

.btnCountry input {
  display: none;
  width: 100%;
  height: 100%;
}

.btnCountrySelected input {
  display: none;
}

.btn {
  background: #4f8eef;
  color: #ffffff;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: start;
  display: flex;
}

.btn:hover {
  background: #769fe7;
}

/*BtnGroup in CreateDraw*/
.btnGroupContainer {
  width: 728px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 20px 0 60px 0;
}

.btnGroupBlock {
  width: 394px;
  height: 100%;
  gap: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 630px) {
    width: 216px;
    gap: 18px;
  }
}
.btnRightArrowBackContainer {
  width: 54px;
  height: 100%;
}

.btnRightArrowBack {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btnSubmitContainer {
  width: 300px;
  height: 100%;
  @media screen and (max-width: 630px) {
    width: 144px;
  }
}

.btnSubmit {
  width: 100%;
  height: 100%;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

/*AddMessageDraw component*/
.containerMessageDraw {
  width: 318px;
  border-radius: 15px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);

  .textarea {
    width: 100%;
  }
}

.containerPhoto {
  width: 100%;
  height: 300px;
}

.containerPhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.messageDraw {
  padding: 0 15px 10px 15px;
  text-align: start;
  position: relative;
}

.messageDraw input {
  width: 100%;
  font-size: 16px;
  color: black;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  margin-top: 25px;
  background: #f5f5f5;
}

.messageDraw textarea {
  resize: vertical;
  font-size: 16px;
}

.messageDrawEnd {
  margin-top: 25px;
  font-size: 16px;
  line-height: normal;
}

.boxBtnEnd {
  display: flex;
  justify-content: center;
}

.boxBtnEnd:last-child {
  right: 50px;
}

.containerAddMessage {
  display: flex;
  justify-content: center;
}

.btnCloseMessage {
  width: 50px;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  right: 60px;
}

.btnOpenMessage {
  width: 313px;
  border-radius: 10px;
  margin-bottom: 30px;
  right: 33px;
  font-size: 14px;
}

/*error in AddMessageDraw in CustomerRoot*/
.errorMessage {
  color: red;
  font-size: 16px;
  @media screen and (max-width: 630px) {
    font-size: 12px;
  }
}

.error {
  font-weight: 400;
  color: red;
  font-size: 12px;
  @media screen and (max-width: 630px) {
    font-size: 10px;
  }
}

/*TooltipComponent in TitleDraw in AddItems, DataPicker, AddAvatar, CountrySelection*/
.tooltipSize div {
  font-size: 30px;
}

/*medias*/
.tooltipIcon:hover {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .containerElement {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/*ANOTHERS COMPONENTS*/

.wrapperTitleDraw {
  margin-bottom: 10px;
}

.title > h2 {
  font-size: 24px;
}

.textDraw {
  display: flex;
  justify-content: center;
  padding: 0 12%;
  line-height: normal;
}

.textDraw p {
  text-align: initial;
  color: #676666;
  font-size: 16px;
}

.textDraw span {
  color: #676666;
  font-weight: 500;
  font-size: 16px;
}

.catalog {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 830px) {
    overflow: hidden;
  }
}

.containerCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 357px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  border-radius: 25px;
  width: 273px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
  position: relative;

  ::before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
  }
}

.image {
  width: 230px;
  height: 230px;
  border-radius: 25px;
  object-fit: cover;
  margin-top: 10px;
}

.image_main {
  width: 230px;
  height: 230px;
  border-radius: 25px;
  object-fit: cover;
  margin-bottom: 10px;
}

.text {
  padding: 0 8%;
  flex-grow: 1;
  word-break: break-all;
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  line-height: 19px;
  width: 90%;
}

.highlight {
  background-color: rgba(245, 245, 245, 1);
  padding: 3px;
  font-weight: 600;
}

.editInput {
  width: 80%;
  height: 40px;
  border: 2px solid #01c2ff;
  font-size: 18px;
}

.btnGift {
  display: flex;
  align-items: center;
  margin: 0;

  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #01c2ff;
  backdrop-filter: blur(12px);
  border-radius: 30px;
}

.btnGift span {
  color: #01c2ff;
  margin-right: 10px;
  font-size: 20px;
}
