.rangeCalendar {
  position: absolute;
  width: 100%;
  padding: 0 1.2rem;
  top: -38rem;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  background-color: var(--color-black-15);
  &.active {
    transform: translateY(39rem);
  }
  :global {
    .rdrCalendarWrapper {
      width: 100%;
      background-color: transparent;
      .rdrMonthAndYearWrapper {
        margin-bottom: 1rem;
        height: 4.08rem;
        .rdrMonthAndYearPickers {
          width: 80%;
          justify-content: flex-start;
          select {
            position: relative;
            color: var(--color-white-3);
            font-size: var(--font-size-s);
            padding-left: 0.5rem;
            background: none;
            &:hover {
              background-color: var(--color-black-15);
            }
          }
          .rdrYearPicker,
          .rdrMonthPicker {
            margin-left: 0.5rem;
            &::after {
              content: '';
              position: absolute;
              transform: translateY(17px) translateX(-20px) rotate(-90deg);
              cursor: pointer;
              border: 4px solid transparent;
              border-style: solid;
              border-right-color: var(--color-white-3);
            }
            &:hover {
              &::after {
                border-right-color: var(--color-violet-1-stat);
              }
            }
          }
        }
        .rdrNextButton,
        .rdrPprevButton {
          order: 3;
          padding: 0;
          width: 5%;
          height: 2rem;
          background-color: transparent !important;
          margin: 0 2rem;
          i {
            transform: translateX(-150%) rotate(-45deg);
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: var(--color-white-3);
            padding: 3px;
          }
          &:hover i {
            border-color: var(--color-violet-1-stat);
          }
        }
        .rdrPprevButton {
          order: 2;
          i {
            transform: rotate(135deg);
          }
        }
      }
      .rdrMonth {
        width: auto;
        padding: 0;
      }
      .rdrWeekDay {
        font-size: var(--font-size-s);
        text-transform: uppercase;
        color: var(--color-white-3);
      }
      .rdrDay {
        position: relative;
        height: 4.3425rem;
        .rdrDayEndPreview,
        .rdrDayStartPreview,
        .rdrDayInPreview {
          color: var(--color-violet-1-stat) !important;
          background-color: var(--color-violet-7-stat) !important;
        }
        .rdrDayInPreview,
        .rdrDayStartPreview {
          width: 100%;
        }
        .rdrDayEndPreview {
          &.rdrDayStartPreview {
            width: 91%;
          }
        }
        .rdrInRange {
          color: var(--color-violet-7-stat) !important;
          left: -2rem;
          width: 200%;
          z-index: 0;
          border-radius: 0;
        }
        &.rdrDayEndOfWeek {
          .rdrDayInPreview,
          .rdrDayStartPreview {
            width: 91%;
          }
        }
        span {
          display: block;
          position: absolute;
          top: 0.2rem;
          left: 0.2rem;
          height: 3.9425rem;
          width: 3.9425rem;
          font-size: var(--font-size-m);
          color: var(--color-white-3);
          span {
            z-index: 20;
            height: 50%;
            width: 50%;
            left: 0.9rem;
          }
        }

        &:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
        &:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
          color: var(--color-violet-8-stat) !important;
        }

        .rdrStartEdge,
        .rdrEndEdge {
          z-index: 20;
          border-radius: 50%;
          color: var(--color-violet-1-stat) !important;
        }
      }

      .rdrDayToday {
        background-color: transparent;
        .rdrDayNumber {
          outline: 0.1rem solid var(--color-violet-1-stat);
          outline-offset: -0.2rem;
          border-radius: 50%;
        }
        span {
          color: var(--color-violet-1-stat);
          &::after {
            width: 0;
            height: 0;
          }
        }
      }
      .rdrDayDisabled {
        background-color: transparent;
        span {
          color: var(--color-black-10);
        }
      }
      .rdrDayPassive {
        span {
          display: none;
        }
      }
    }
  }
}
