.faq {
  padding-bottom: 80px;
  @media (max-width: 993px) {
    padding-bottom: 32px;
  }
  @media (max-width: 577px) {
    padding-bottom: 16px;
  }
}
.subTitle {
  margin-bottom: 40px;
  @media (max-width: 992px) {
    margin-bottom: 24px;
  }
  @media (max-width: 577px) {
    font-size: 12px;
  }
}
