@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';

.adminTeamBlock {
  @include flex(column, flex-start, center);
  margin: 0;

  h3 {
    @include text($gilroyFont, 700, 20px, 25px);
    @include adapt-font(20, 16, 25, 20);
  }
}
