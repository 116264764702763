@import '../../../../../../styles/mixins';

.account {
  position: relative;
  .bgcRight,
  .bgcLeft {
    position: absolute;
    top: 0;
    height: 100%;
    @media (max-width: 1920px) {
      display: none;
    }
  }
  .bgcRight {
    right: 0;
    width: 30%;
    background-color: var(--color-violet-4);
  }
  .bgcLeft {
    left: 0;
    width: 40%;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    @media (max-width: 577px) {
      flex-direction: column-reverse;
    }
  }
}
.left {
  @include adapt-padding-top-bottom(120, 16);
  @include adapt-padding-left(80, 16);
  padding-right: 20px;
  flex: 0 0 59%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 993px) {
    flex: 0 0 55%;
  }
  @media (max-width: 769px) {
    padding: 40px 10px 55px 32px;
  }
  @media (max-width: 577px) {
    padding: 32px 16px 16px 16px;
  }
  .desc {
    margin-bottom: 40px;
    @media (max-width: 993px) {
      margin-bottom: 24px;
    }
    @media (max-width: 577px) {
      font-size: 10px;
      letter-spacing: 0.2px;
      margin-top: -4px;
    }
  }
}
.right {
  flex: 1 1 auto;
  background-color: var(--color-violet-4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  @media (max-width: 577px) {
    padding-top: 10%;
  }
  .img {
    height: 80%;
    display: flex;
    align-items: flex-end;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      @media (max-width: 577px) {
        height: 320px;
      }
    }
  }
}
