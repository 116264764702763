@import './variable';
//@mixin transition($properties, $duration: 0.2s, $timing-function: ease-in-out) {
//  transition: $properties $duration $timing-function;
//}

@mixin adapt-font-L-M($pcFontSize, $mobFontSize, $pcLS, $mobLS) {
  $maxWidth: 1440;
  $addSizeFont: $pcFontSize - $mobFontSize;
  $addLS: $pcLS - $mobLS;
  $maxWidth: $maxWidth - 768;
  font-size: calc(#{$mobFontSize + px} + #{$addSizeFont} * ((100vw - 768px) / #{$maxWidth}));
  letter-spacing: calc(#{$mobLS + px} + #{$addLS} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-font-M-S($pcFontSize, $mobFontSize, $pcLS, $mobLS) {
  $maxWidth: 768;
  $addSizeFont: $pcFontSize - $mobFontSize;
  $addLS: $pcLS - $mobLS;
  $maxWidth: $maxWidth - 360;
  font-size: calc(#{$mobFontSize + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
  letter-spacing: calc(#{$mobLS + px} + #{$addLS} * ((100vw - 360px) / #{$maxWidth}));
}

$maxWidth: 1440;
@mixin adapt-font($pcFontSize, $mobFontSize) {
  $addSizeFont: $pcFontSize - $mobFontSize;
  $maxWidth: $maxWidth - 360;
  font-size: calc(#{$mobFontSize + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-letter-spacing($pcLetterSpacing, $mobLetterSpacing) {
  $addSizeFont: $pcLetterSpacing - $mobLetterSpacing;
  $maxWidth: $maxWidth - 360;
  letter-spacing: calc(#{$mobLetterSpacing + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-left($pcPaddingLeft, $mobPaddingLeft) {
  $addSizeFont: $pcPaddingLeft - $mobPaddingLeft;
  $maxWidth: $maxWidth - 360;
  padding-left: calc(#{$mobPaddingLeft + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-right($pcPaddingRight, $mobPaddingRight) {
  $addSizeFont: $pcPaddingRight - $mobPaddingRight;
  $maxWidth: $maxWidth - 360;
  padding-right: calc(#{$mobPaddingRight + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-top($pcPaddingTop, $mobPaddingTop) {
  $addSizeFont: $pcPaddingTop - $mobPaddingTop;
  $maxWidth: $maxWidth - 360;
  padding-top: calc(#{$mobPaddingTop + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-bottom($pcPaddingBottom, $mobPaddingBottom) {
  $addSizeFont: $pcPaddingBottom - $mobPaddingBottom;
  $maxWidth: $maxWidth - 360;
  padding-bottom: calc(#{$mobPaddingBottom + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-top-bottom($pcPaddingTopBottom, $mobPaddingTopBottom) {
  $addSizeFont: $pcPaddingTopBottom - $mobPaddingTopBottom;
  $maxWidth: $maxWidth - 360;
  padding-bottom: calc(#{$mobPaddingTopBottom + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
  padding-top: calc(#{$mobPaddingTopBottom + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-padding-left-right($pcPaddingLeftRight, $mobPaddingLeftRight) {
  $addSizeFont: $pcPaddingLeftRight - $mobPaddingLeftRight;
  $maxWidth: $maxWidth - 360;
  padding-left: calc(#{$mobPaddingLeftRight + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
  padding-right: calc(#{$mobPaddingLeftRight + px} + #{$addSizeFont} * ((100vw - 360px) / #{$maxWidth}));
}

@mixin adapt-paddingTB($pcPaddingTopBottom, $mobPaddingTopBottom, $maxWidth, $minWidth) {
  $addSizePadding: $pcPaddingTopBottom - $mobPaddingTopBottom;
  $maxWidthDiff: $maxWidth - $minWidth;
  padding-bottom: calc(#{$mobPaddingTopBottom}px + #{$addSizePadding} * ((100vw - #{$minWidth}px) / #{$maxWidthDiff}));
  padding-top: calc(#{$mobPaddingTopBottom}px + #{$addSizePadding} * ((100vw - #{$minWidth}px) / #{$maxWidthDiff}));
}

@mixin adapt-paddingLR($pcPaddingLeftRight, $mobPaddingLeftRight, $maxWidth, $minWidth) {
  $addSizePadding: $pcPaddingLeftRight - $mobPaddingLeftRight;
  $maxWidthDiff: $maxWidth - $minWidth;
  padding-left: calc(#{$mobPaddingLeftRight}px + #{$addSizePadding} * ((100vw - #{$minWidth}px) / #{$maxWidthDiff}));
  padding-right: calc(#{$mobPaddingLeftRight}px + #{$addSizePadding} * ((100vw - #{$minWidth}px) / #{$maxWidthDiff}));
}

@mixin transition($name) {
  transition: $name 0.3s ease-in-out;
}

/* Media Queries */

@mixin respond($breakpoint) {
  //360px
  @if $breakpoint == phone {
    @media (max-width: $screen-size-small) {
      @content;
    }
  }
  //768px
  @if $breakpoint == medium {
    @media (max-width: $screen-size-average) {
      @content;
    }
  }
  //1440px
  @if $breakpoint == large {
    @media (min-width: $screen-size-large) {
      @content;
    }
  }
}
